import PrintIcon from '@mui/icons-material/Print';
import { Box } from '@mui/material';
import { InvoicePDF } from 'interfaces/invoice';

// open PDF received as Base64 encoded string in new tab
const openPdf = (basePdf: string) => {
  const byteCharacters = atob(basePdf);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf;base64' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export function PrintPDF({ data }: { data: InvoicePDF }) {
  return (
    <Box onClick={() => (data ? openPdf(data.fileBase64) : undefined)} display="flex" alignItems="center">
      <PrintIcon color="primary" />
    </Box>
  );
}
