import { ReactNode } from 'react';
// material-ui
import { Box, Card } from '@mui/material';
import { SelectLanguage } from 'components/SelectLanguage';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Card
      sx={{
        maxWidth: { xs: 400, md: 475 },
        margin: { xs: 1, md: 3 },
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%',
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" mt={1} mr={1}>
        <SelectLanguage />
      </Box>
      <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
    </Card>
  );
};

export default AuthCardWrapper;
