import { Notification } from 'interfaces/notification';
import { transport } from 'src/utils/transport';

const apiURL = import.meta.env.VITE_API_URL;

export const NotificationEndpoints = {
  allNotifications: `${apiURL}/api/notifications/all`,
  updateSeen: `${apiURL}/api/notifications/update`,
};

export const getNotifications = (): Promise<Notification[]> => {
  return transport.get(NotificationEndpoints.allNotifications);
};

export const setNotificationSeen = (notificationId: number): Promise<any> => {
  return transport.post(NotificationEndpoints.updateSeen, { notificationId });
};
