import { useForm, SubmitHandler } from 'react-hook-form';
import { Alert, AlertTitle, Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { resetPasswordToken } from 'src/api/auth';
import { PasswordField } from './PasswordField';
import { CircleLoader } from 'components/CircleLoader';

// ===========================|| RESET PASSWORD ||=========================== //

type Inputs = {
  password: string;
  confirm: string;
};

const ResetPasswordForm = ({ ...others }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const form = useForm<Inputs>();
  const { t } = useTranslation();
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [formState, setFormState] = useState<'initial' | 'submitting' | 'success' | 'error'>('initial');

  const resetPasswordMutation = useMutation(resetPasswordToken, {
    onSuccess: () => {
      setFormState('success');
    },
    onError: () => {
      setFormState('error');
    },
  });

  useEffect(() => {
    if (!searchParams.get('userId') || !searchParams.get('token')) {
      navigate('/');
    }
  }, [searchParams]);

  const userId = searchParams.get('userId');
  const token = searchParams.get('token');

  if (!userId || !token) return null;

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (data.password !== data.confirm) {
      setPasswordNotMatch(true);
    }
    if (data.password === data.confirm) {
      setFormState('submitting');
      resetPasswordMutation.mutate({ password: data.password, userId, token });
    }
  };

  return (
    <>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)} {...others}>
        {formState === 'initial' ? (
          <>
            <Typography gutterBottom variant="body1" pb={4} textAlign="center">
              {t('auth.reset_password_message')}
            </Typography>
            <Stack spacing={1}>
              <PasswordField
                {...form}
                label={t('auth.fields.password')}
                fieldName="password"
                textFieldProps={{ autoFocus: true }}
              />
              <PasswordField
                {...form}
                label={t('auth.fields.confirm_password')}
                fieldName="confirm"
                hideLevel
                validations={false}
              />
              {passwordNotMatch ? <Alert severity="error">{t('auth.validations.password.not_matched')}</Alert> : null}
            </Stack>
            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={form.formState.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('auth.send')}
              </Button>
            </Box>
          </>
        ) : formState === 'submitting' ? (
          <CircleLoader text={t('auth.submitting')} />
        ) : formState === 'success' ? (
          <Alert severity="success">
            <AlertTitle>{t('auth.reset_password_link.success.title')}</AlertTitle>
            {t('auth.reset_password_link.success.description')}
          </Alert>
        ) : formState === 'error' ? (
          <Alert severity="error">
            <AlertTitle>{t('auth.reset_password_link.error.title')}</AlertTitle>
            {t('auth.reset_password_link.error.description')}{' '}
            <Typography
              component={Link}
              to="/forgot-password"
              variant="subtitle1"
              color="primary"
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              {t('auth.reset_password_link.error.here')}
            </Typography>
          </Alert>
        ) : null}
      </form>
    </>
  );
};

export default ResetPasswordForm;
