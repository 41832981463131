import { Alert, AlertTitle } from '@mui/material';
import { CustomCard } from 'components/CustomCard';
import { useTranslation } from 'react-i18next';

export default function NoClients() {
  const { t } = useTranslation();
  return (
    <CustomCard>
      <Alert severity="info" variant="outlined" sx={{ borderWidth: 0 }}>
        <AlertTitle>{t('no_clients.title')}</AlertTitle>
        {t('no_clients.description')}
      </Alert>
    </CustomCard>
  );
}
