import { Client, ClientInfo } from 'interfaces/client';
import { transport } from 'src/utils/transport';

const apiURL = import.meta.env.VITE_API_URL;

export const clientEndpoints = {
  clientData: (clientId: string) => `${apiURL}/api/client/${clientId}`,
  clientInfo: (clientId: string) => `${apiURL}/api/client/${clientId}/information`,
  clientContact: `${apiURL}/api/client/contact`,
  customerNumbers: (clientId: string) => `${apiURL}/api/client/${clientId}/customerNumbers`,
};

export const getClient = (clientId: string): Promise<Client> => {
  return transport.get(clientEndpoints.clientData(clientId));
};

export const getclientInfo = (clientId: string): Promise<ClientInfo> => {
  return transport.get(clientEndpoints.clientInfo(clientId));
};

export const contactClient = (data: {
  subject: string;
  message: string;
  fromMail: string;
  clientId: string;
}): Promise<object> => transport.post(clientEndpoints.clientContact, data);

export const getCustomerNumbers = (clientId: string): Promise<string[]> => {
  return transport.get(clientEndpoints.customerNumbers(clientId));
};
