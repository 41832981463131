import type { FieldPath, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { alpha, styled } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { strengthColor, strengthIndicator } from 'src/utils/password-strength';

interface Level {
  label: string;
  color: string;
  value: number;
}

type Fields = Record<string, any>;
interface FieldProps<TField extends Fields = Fields> extends UseFormReturn<TField, any> {
  fieldName: FieldPath<TField>;
  label: string;
  hideLevel?: boolean;
  validations?: boolean;
  textFieldProps?: TextFieldProps;
}

const BorderLinearProgress = styled(LinearProgress)(({ level }: { level: Level }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: alpha(level.color, 0.2),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: level.color,
  },
}));

export function PasswordField<TField extends Fields = Fields>({
  formState,
  register,
  trigger,
  setValue,
  fieldName,
  label,
  hideLevel = false,
  validations = true,
  textFieldProps,
}: FieldProps<TField>) {
  const [showPassword, setShowPassword] = useState(false);

  const [_, setStrength] = useState(0);
  const [level, setLevel] = useState<Level>();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const changePassword = (value: PathValue<TField, Path<TField>>) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
    setValue(fieldName, value);
    trigger(fieldName);
  };
  const { t } = useTranslation();

  const fieldValidations = validations
    ? {
        required: { value: true, message: t('auth.validations.password.required') },
        maxLength: { value: 20, message: t('auth.validations.password.max', { count: 20 }) },
        minLength: { value: 8, message: t('auth.validations.password.min', { count: 8 }) },
        validate: (value: any) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(value) !== true
            ? (t('auth.validations.password.valid', { count: 8 }) as string)
            : undefined,
      }
    : {};

  return (
    <FormControl fullWidth>
      <TextField
        fullWidth
        label={label}
        error={Boolean(formState.errors[fieldName])}
        helperText={formState.errors[fieldName]?.message as string}
        type={showPassword ? 'text' : 'password'}
        {...register(fieldName, fieldValidations)}
        onChange={e => {
          changePassword(e.target.value as PathValue<TField, Path<TField>>);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="large"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...textFieldProps}
      />
      {!hideLevel && level ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <BorderLinearProgress variant="determinate" level={level} value={level.value} />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1" fontSize="0.75rem">
              {t(`auth.validations.password.strength.${level?.label}`)}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </FormControl>
  );
}
