import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localeLS, locales } from 'src/utils/enums';
import TranslateIcon from '@mui/icons-material/Translate';
import { GTM } from 'src/utils/gtm';

export function SelectLanguage() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    GTM.selectLanguageButton();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (newLanguage: string) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem(localeLS, newLanguage);
    handleClose();
  };

  return (
    <>
      <Button
        variant="text"
        disableElevation
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<TranslateIcon />}
        sx={{
          bgcolor: theme => (theme.palette.mode === 'light' ? theme.palette.grey['300'] : theme.palette.grey['800']),
          color: theme => (theme.palette.mode === 'light' ? theme.palette.neutral[600] : theme.palette.grey['400']),
          ':hover': {
            color: theme =>
              theme.palette.mode === 'light' ? theme.palette.secondary.dark : theme.palette.secondary.light,
            bgcolor: theme =>
              theme.palette.mode === 'light' ? theme.palette.coldBlue['200'] : theme.palette.grey['900'],
          },
        }}
      >
        {language}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {locales.map(locale => (
          <MenuItem
            key={locale.value}
            selected={locale.value === language}
            onClick={() => handleLanguageChange(locale.value)}
          >
            <ListItemIcon>
              <Box component="img" borderRadius={1} width={25} src={locale.flag} alt={locale.value} />
            </ListItemIcon>
            <ListItemText>{locale.value.toUpperCase()}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
