// material-ui
import { Box, Container } from '@mui/material';
import { CssBaseline } from '@mui/material';
import FrequentlyAskedQuestion from 'components/FAQ';
import ScrollToTop from 'components/ScrollToTop';
import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

export default function MainLayout() {
  const footerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!footerRef.current) return; // wait for the footerRef to be available
    const resizeObserver = new ResizeObserver(() => {
      if (footerRef.current) {
        setHeight(footerRef.current?.clientHeight);
      }
    });
    resizeObserver.observe(footerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <>
      <CssBaseline />
      <Header />
      <Box
        component="main"
        sx={{
          background: theme => theme.palette.background.default,
          width: '100%',
          minHeight: `calc(100vh - ${height}px)`,
        }}
      >
        <Container maxWidth="lg">
          <ScrollToTop />
          <Box minHeight={400}>
            <Outlet />
          </Box>
        </Container>
        <Box
          sx={{
            background: theme =>
              `linear-gradient(${theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[600]}, ${
                theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900]
              })`,
          }}
        >
          <Box
            component="svg"
            sx={{
              marginTop: 6,
              left: 0,
              right: 0,
              display: 'block',
              bgcolor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900]),
              fill: theme => (theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]),
            }}
            viewBox="0 0 1440 94"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 .91c37.102-3.789 72.529 8.207 112.12 35.083 104.792 71.133 186.932 18.896 299.41-18.297 112.476-37.193 128.544 64.109 218.349 43.87 89.805-20.238 155.519-61.91 327.519-21.16s253.141 66.825 357.309 36.95c49.177-14.104 85.113-37.259 125.293-57.88V94H0V.91z"></path>
          </Box>
          <Container fixed>
            <Box mt={2} mb={6} display="flex" justifyContent="center">
              <FrequentlyAskedQuestion />
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer ref={footerRef} />
    </>
  );
}
