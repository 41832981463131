import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FrequentlyAskedQuestion() {
  const { t } = useTranslation();
  const faqs = t('faq.questions', { returnObjects: true }) as Array<{ q: string; a: string }>;

  return (
    <>
      <Box display="flex" maxWidth={600} justifyContent="center" alignItems="center" flexDirection="column">
        <Typography variant="h5" pb={2}>
          {t('faq.title')}
        </Typography>
        <Card>
          {Array.isArray(faqs)
            ? faqs.map((faq, index) => (
                <Accordion key={faq.q} disableGutters sx={{ width: '100%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-content-${index}`}
                    id={`panel-header-${index}`}
                  >
                    <Typography sx={{ fontWeight: '500', py: 1 }} textAlign="start">
                      {faq.q}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography textAlign="start">{faq.a}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            : null}
        </Card>
      </Box>
    </>
  );
}
