import { IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';

export default function CopyClipboard({
  value,
  clipboardMessage,
}: {
  value: string | number;
  clipboardMessage: string;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function activateTooltip(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setAnchorEl(null);
    }, 2000);
  }
  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={16}>
            {t('ui.copy')} {clipboardMessage}{' '}
          </Typography>
        }
        enterTouchDelay={50}
        leaveTouchDelay={3000}
        placement="top"
      >
        <IconButton
          aria-label="copy"
          size="small"
          onClick={event => {
            navigator.clipboard.writeText(String(value));
            activateTooltip(event);
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 1 }}>{t('ui.copied')}</Typography>
      </Popover>
    </>
  );
}
