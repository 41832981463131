import { CircularProgress, Divider, Grid, Hidden, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { setCurrency } from 'src/utils/currency';
import { Client } from 'interfaces/client';
import { CustomCard } from 'src/components/CustomCard';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DateField } from 'components/DateField';

interface Stats {
  icon: any;
  color: 'info' | 'success' | 'orange';
  title: string;
  data: string | number;
}

interface Data {
  invoices: number;
  totalAmount: number;
  countryCode: Client['countryCode'];
  nextDueDate: string;
}

export default function Statistics({ data }: { data: Data }) {
  const { t, i18n } = useTranslation();
  const nextDueDate = data.nextDueDate ? <DateField toDate={data.nextDueDate} /> : '';
  const statistics = useMemo(
    () => [
      {
        icon: ReceiptIcon,
        color: 'info',
        title: t('page.dashboard.stats.unpaidInvoices'),
        data: data.invoices,
      },
      {
        icon: PaymentIcon,
        color: 'success',
        title: t('page.dashboard.stats.totalAmount'),
        data: setCurrency(data.totalAmount, data.countryCode),
      },
      {
        icon: CalendarMonthIcon,
        color: 'orange',
        title: t('page.dashboard.stats.nextDueDate'),
        data: nextDueDate,
      },
    ],
    [data, i18n.language]
  ) as Stats[];

  return (
    <CustomCard sx={{ mb: 2, px: [2, 2, 0] }}>
      <Grid container>
        {statistics.map((stat, index) =>
          stat.data !== '' ? (
            <Grid
              item
              sm={12 / statistics.filter(s => s.data !== '').length}
              xs={12}
              key={stat.title}
              position="relative"
            >
              <Box display="flex" justifyContent={['flex-start', 'flex-start', 'center']} py={[1, 1, 2]}>
                <StatBox {...stat} />
              </Box>
              {index !== statistics.length - 1 && statistics[index + 1].data !== '' ? (
                <Hidden mdDown>
                  <Divider
                    orientation="vertical"
                    flexItem
                    variant="middle"
                    sx={{
                      m: '0px',
                      flexShrink: 0,
                      borderWidth: '0px thin 0px 0px',
                      borderColor: 'rgba(145, 158, 171, 0.24)',
                      alignSelf: 'stretch',
                      borderStyle: 'dashed',
                      position: 'absolute',
                      top: '10%',
                      right: 0,
                      height: '80%',
                    }}
                  />
                </Hidden>
              ) : null}
            </Grid>
          ) : null
        )}
      </Grid>
    </CustomCard>
  );
}

function StatBox({ color, title, data, icon: Icon }: Stats) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: ['40px', '40px', '56px'],
          height: ['40px', '40px', '56px'],
        }}
      >
        <Icon color={color} sx={{ width: '50%', height: '50%', position: 'absolute' }} />
        <CircularProgress
          //@ts-ignore
          color={color}
          variant="determinate"
          value={100}
          size="100%"
          sx={{
            display: 'inline-block',
            opacity: 0.5,
            position: 'absolute',
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
        <Typography variant="h6" fontSize={['1rem', '1rem', '1.25rem']}>
          {title}
        </Typography>
        <Typography fontSize={[14, 14, 16]} fontWeight={1}>
          {data === -1 || data === null ? <Skeleton /> : data}
        </Typography>
      </Box>
    </Box>
  );
}
