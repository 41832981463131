import {
  NavigatorSubtype,
  isAndroidDevice,
  isIosChrome,
  isIosDevice,
  isIosSafari,
  isIosWebView,
} from 'src/utils/user-agent';

interface OpenBankIdArgs {
  /**
   * The autostart token, for auto start the sign with the BankId app.
   */
  autoStartToken?: string;
  /**
   * The uri to get back to after the user has signed in the bankid app.
   */
  redirectUri?: string;
  /**
   * Only pass this argument for mocking the navigator object or if you know what you are doing.
   */
  navigator?: NavigatorSubtype;
}

/**
 * Create a url to BankId. `autostarttoken` should be before `redirect` in the url according to the spec
 */
const bankIdUrlString = (redirectUrl = 'null', autoStartToken?: string): string => {
  const query = [
    autoStartToken && `autostarttoken=${encodeURIComponent(autoStartToken)}`,
    `redirect=${encodeURIComponent(redirectUrl)}`,
  ]
    .filter(Boolean)
    .join('&');
  return `bankid:///?${query}`;
};

// iOS needs to specify the redirectUrl to open previous app, unlike Android.
const redirectUrlForIOS = (userAgent: string, currentLocation: string): string => {
  if (isIosChrome(userAgent)) {
    if (/^https/.test(currentLocation)) {
      return 'googlechromes:///';
    } else {
      return 'googlechrome:///';
    }
  } else if (isIosSafari(userAgent)) {
    // A hashtag is added to url for safari to open without reloading the page.
    const cleanCurrentLocation = currentLocation.split('#')[0];
    return `${cleanCurrentLocation}#${Date.now()}`;
  }
  // The string 'null' means that bankId should not redirect back from BankId on iOS
  return 'null';
};

/**
 * @internal
 * Get bankid url depending on a combination of OS and device.
 */
function getBankIdUrl(navigator: NavigatorSubtype, redirectUri: string, autoStartToken?: string): string | null {
  if (isAndroidDevice(navigator.userAgent)) {
    // After signing the user will be redirected to previous app
    return bankIdUrlString(undefined, autoStartToken);
  } else if (isIosDevice(navigator)) {
    // After signing the user will be redirected to previous browser only for Chrome or Safari browsers
    return bankIdUrlString(redirectUrlForIOS(navigator.userAgent, redirectUri), autoStartToken);
  }
  // For desktop, do not do anything
  return bankIdUrlString(undefined, autoStartToken);
}

function getRedirectUri(href?: string): string {
  if (href) {
    return href;
  } else {
    return window.location.href;
  }
}

/**
 * Open the BankId app.
 * It will try to figuring out the right bankid uri to open the app.
 * If the web app is in a iframe, we will send a post message to the
 * parent instead of trying to opening up the app.
 */
export function openBankId({ autoStartToken, navigator = window.navigator, redirectUri }: OpenBankIdArgs = {}): void {
  const bankIdUrl = getBankIdUrl(navigator, getRedirectUri(redirectUri), autoStartToken);

  if (bankIdUrl) {
    if (isIosWebView(navigator)) {
      // If the webpage is used in a "in app web view" on iOS and the native app doesn’t support
      // to open BankID from a web view an error will be thrown without `setTimeout`.
      // The time, 500ms, is tested. A lower timeout will still throw.
      setTimeout(() => window.location.replace(bankIdUrl), 500);
    } else {
      // For Android in app or in a web browser, just open bankid right away
      window.location.replace(bankIdUrl);
    }
  }
}
