import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function Breadcrumbs({
  crumbs,
  isLoading,
}: {
  crumbs: Array<{ path?: string; name: string }>;
  isLoading?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" separator="›">
      <MuiLink component={Link} sx={{ ':hover': { color: 'text.primary' } }} underline="none" color="inherit" to="/">
        {t('page.dashboard.title')}
      </MuiLink>
      {crumbs.map(crumb =>
        !crumb.path ? (
          <Typography key={crumb.name} color="text.primary" sx={{ fontWeight: '500' }}>
            {isLoading ? <Skeleton width={70} /> : crumb.name}
          </Typography>
        ) : (
          <MuiLink
            key={crumb.path}
            component={Link}
            sx={{ ':hover': { color: 'text.primary' } }}
            underline="none"
            color="inherit"
            to={'/' + crumb.path}
          >
            {isLoading ? <Skeleton width={70} /> : crumb.name}
          </MuiLink>
        )
      )}
    </MuiBreadcrumbs>
  );
}
