import { Link } from 'react-router-dom';

// material-ui
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';

// project imports
import logoGray from 'src/assets/images/nordfin_logo_gray.svg';
import logoWhite from 'src/assets/images/nordfin_logo_white.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ small }: { small?: boolean }) => {
  const theme = useTheme();
  return (
    <ButtonBase disableRipple component={Link} to="/">
      <Box
        component="img"
        src={theme.palette.mode === 'light' ? logoGray : logoWhite}
        alt="Logo"
        sx={{ opacity: theme.palette.mode === 'light' ? 0.6 : 1, height: small ? '25px' : '45px' }}
      />
      <Typography
        color="secondary"
        fontSize={small ? '2rem' : '3.75rem'}
        component="h2"
        fontWeight="bold"
        sx={{ pl: 1 }}
      >
        Nord
        <Box component="span" color="grey.400">
          fin
        </Box>
      </Typography>
    </ButtonBase>
  );
};

export default LogoSection;
