import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Box, CircularProgress, useTheme } from '@mui/material';
import { Divider, Grid, Stack, Typography } from '@mui/material';

// project imports
import AuthWrapper from 'components/auth/AuthWrapper';
import AuthCardWrapper from 'components/auth/AuthCardWrapper';
import Logo from 'src/layouts/Logo';
import { useEffect, useState } from 'react';
import { registerCustomerToken } from 'src/api/auth';
import { useAuthentication } from 'src/hooks/use-auth';

// ===============================|| AUTH - REGISTER ||=============================== //

const RegisterConfirm = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 1 }}>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                      <Typography
                        color={theme.palette.mode === 'light' ? theme.palette.neutral[700] : theme.palette.neutral[300]}
                        gutterBottom
                        variant="h5"
                      >
                        {t('auth.register_message')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <ConfirmState />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="column" alignItems="center" xs={12}>
                      <Typography
                        component={Link}
                        to="/"
                        variant="subtitle1"
                        color="primary"
                        sx={{ textDecoration: 'none' }}
                      >
                        {t('auth.already_customer')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

function ConfirmState() {
  const [searchParams] = useSearchParams();
  const { signOut } = useAuthentication();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!searchParams.get('userId') || !searchParams.get('token')) {
      navigate('/');
    }
  }, [searchParams]);

  const userId = searchParams.get('userId');
  const token = searchParams.get('token');

  if (!userId || !token) return null;

  useEffect(() => {
    registerCustomerToken({ userId, token })
      .then(() => {
        setStatus('success');
        signOut();
      })
      .catch(() => setStatus('error'));
  }, []);

  return status === 'success' ? (
    <Alert severity="success">{t('auth.register.email_verified')}</Alert>
  ) : status === 'error' ? (
    <Alert severity="error">{t('auth.register.expired')}</Alert>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size="120px" />
    </Box>
  );
}

export default RegisterConfirm;
