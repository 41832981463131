import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { locales } from 'src/utils/enums';
import en from '../locales/en/general.json';
import sv from '../locales/sv/general.json';
import fi from '../locales/fi/general.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: locales.map(({ value }) => value),
    fallbackLng: 'en',
    ns: ['general'],
    resources: {
      en: {
        general: en,
      },
      sv: {
        general: sv,
      },
      fi: {
        general: fi,
      },
    },
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage', 'navigator'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false },
  });

export default i18n;
