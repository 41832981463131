import * as Sentry from '@sentry/react';

export const sentry = {
  init: () => {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      environment: import.meta.env.VITE_ENVIRONMENT,
      tracesSampleRate: import.meta.env.PROD ? 0.5 : 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  },
  setUser: (user: string) => {
    Sentry.setUser({ username: user });
  },
  setLocale: (locale: string) => {
    Sentry.setTag('page_locale', locale);
  },
};
