import Loader from 'components/Loader';
import { useAuthentication } from 'src/hooks/use-auth';
import { useEffect } from 'react';
import AuthLogin from 'src/pages/Login/Login';
import { useLocation } from 'react-router-dom';
import { GTM } from './gtm';

export function AuthGuard({ children }: { children: any }) {
  const { status, validate } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    GTM.pageView({ path: location.pathname });
  }, [location]);

  useEffect(() => {
    validate();
  }, []);

  switch (status) {
    case 'initial':
      return <Loader />;
    case 'authenticated':
      return children;
    default:
      return <AuthLogin />;
  }
}
