import MinimalLayout from 'src/layouts/MinimalLayout';

// login routing
import AuthLogin from 'src/pages/Login/Login';
import AuthRegister from 'src/pages/Login/Register';
import AuthRegisterConfirm from 'src/pages/Login/RegisterConfirm';
import AuthForgotPassword from 'src/pages/Login/ForgotPassword';
import AuthResetPassword from 'src/pages/Login/ResetPassword';
import AuthBankID from 'src/pages/Login/BankID';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />,
    },
    {
      path: '/register',
      element: <AuthRegister />,
    },
    {
      path: '/register-confirm',
      element: <AuthRegisterConfirm />,
    },
    {
      path: '/forgot-password',
      element: <AuthForgotPassword />,
    },
    {
      path: '/reset-password',
      element: <AuthResetPassword />,
    },
    {
      path: '/bank-id',
      element: <AuthBankID />,
    },
  ],
};

export default AuthenticationRoutes;
