import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './localization/i18n';
import { sentry } from './utils/sentry';

/* ---- MSW ---- */
const isMswMode = import.meta.env.MODE === 'msw';
if (isMswMode) {
  const browser = import('../mocks/browser');
  browser.then(({ worker }) => {
    worker.start({ onUnhandledRequest: 'bypass' });
  });
}

/* --- SENTRY --- */
if (import.meta.env.VITE_ENVIRONMENT === 'production') {
  sentry.init();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
