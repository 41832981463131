/* ------------------------------- MATERIAL UI IMPORT -------------------------------- */
import { CircularProgress, Stack, Typography } from '@mui/material';
/* ------------------------------- PROJECT IMPORT -------------------------------- */
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BankIdState } from 'src/hooks/use-bankID';

export function BankIDPending({ state, children }: { state: BankIdState; children: ReactNode }) {
  const { t } = useTranslation();
  return (
    <>
      {['pending_auth', 'pending_poll', 'awaiting_poll'].some(key => state.status === key) ? (
        <Stack display="flex" flexDirection="column" alignItems="center" spacing={3}>
          <CircularProgress size={120} />
          {state.progress ? (
            <Typography textAlign="center">
              {t(`auth.bankID.status.${state.progress?.status}.${state.progress?.hintCode}`)}
            </Typography>
          ) : null}
        </Stack>
      ) : (
        /* HERE WE RENDER TWO SIGN WITH BANKID BUTTONS  */
        children
      )}
    </>
  );
}
