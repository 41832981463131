import { useEffect, useRef, useState } from 'react';
import * as pdfjs from 'pdfjs-dist';
import { useEventListener } from 'src/hooks/use-event-listener';
import { Alert, AlertTitle, Box, Skeleton, useTheme } from '@mui/material';
import { CustomCard } from 'components/CustomCard';
import { useTranslation } from 'react-i18next';

export function PDF({ pdfFile, fetching }: { pdfFile?: string; fetching?: boolean }) {
  const pdfRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<'loading' | 'success' | 'error'>('loading');
  const theme = useTheme();
  const { t } = useTranslation();

  useEventListener('resize', () => {
    const canvas = document.getElementsByTagName('canvas');
    for (const item of canvas) {
      const width = pdfRef.current?.clientWidth;
      item.style.width = `${width}px`;
      item.style.height = `auto`;
    }
  });

  async function renderPDF(fileBase64?: string) {
    if (!fileBase64 || fileBase64 === '') {
      setState('error');
      return;
    }
    // We import this here so that it's only loaded during client-side rendering.
    pdfjs.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';
    try {
      const pdf = await pdfjs.getDocument({ data: atob(fileBase64) }).promise;
      if (pdfRef.current) {
        while (pdfRef.current.firstChild) {
          pdfRef.current.lastChild && pdfRef.current.removeChild(pdfRef.current.lastChild);
        }
      }
      const resolution = 3;
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 1.5 });
        const container = document.createElement('div');
        const containerStyle = {
          position: 'relative',
          marginBottom: '16px',
          bgcolor: 'white',
          boxShadow: theme.shadows[1],
          borderRadius: '4px',
          overflow: 'hidden',
        };
        Object.assign(container.style, containerStyle);
        const canvas = document.createElement('canvas');
        const textLayout = document.createElement('div');

        const canvasContext = canvas.getContext('2d', { alpha: false });
        if (canvasContext) {
          canvas.height = resolution * viewport.height;
          canvas.width = resolution * viewport.width;
          canvas.style.display = 'block';
          canvas.style.width = `${pdfRef.current?.clientWidth}px`;
          canvas.style.height = `auto`;
          canvasContext?.scale(resolution, resolution);
          const renderContext = { canvasContext, viewport };
          page.render(renderContext);
          page.getTextContent().then(textContent => {
            pdfjs.renderTextLayer({
              textContentSource: textContent,
              container: textLayout,
              viewport: viewport,
              textDivs: [],
            });
            const textCss = {
              position: 'absolute',
              inset: 0,
              overflow: 'hidden',
              opacity: 0.2,
              width: 'auto',
              height: 'auto',
            };
            Object.assign(textLayout.style, textCss);
          });
        }
        container.appendChild(canvas);
        container.appendChild(textLayout);
        pdfRef.current?.appendChild(container);
        setState('success');
      }
    } catch {
      setState('error');
    }
  }

  useEffect(() => {
    if (fetching) {
      return;
    }
    if (state === 'loading') {
      renderPDF(pdfFile);
    }
  }, [fetching]);

  return (
    <>
      <Box
        ref={pdfRef}
        sx={{
          span: {
            color: 'transparent',
            position: 'absolute',
            whiteSpace: 'pre',
            cursor: 'text',
            transformOrigin: '0% 0%',
          },
        }}
      />
      {fetching || state === 'loading' ? (
        <Skeleton
          sx={{
            opacity: 0.5,
            width: '100%',
            transform: 'scale(1)',
          }}
          height={800}
          animation="wave"
        />
      ) : state === 'error' ? (
        <CustomCard>
          <Alert severity="warning" variant="outlined" sx={{ borderWidth: 0 }}>
            <AlertTitle>{t('no_invoice.pdf.title')}</AlertTitle>
            {t('no_invoice.pdf.description')}
          </Alert>
        </CustomCard>
      ) : null}
    </>
  );
}
