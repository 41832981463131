export type NavigatorSubtype = Pick<Navigator, 'platform' | 'maxTouchPoints' | 'userAgent'>;

/**
 * Check if we are in a Android device.
 * This is true for in-app-view as well.
 */
export const isAndroidDevice = (userAgent: string): boolean => {
  return /android/i.test(userAgent);
};

/**
 * Check if we are in a iOS device.
 * This is true for in-app-view as well.
 */
export const isIosDevice = (navigator: NavigatorSubtype): boolean => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
};

/**
 * Check if we are in a iOS webview.
 * Safari is present for all browsers except for in-app-view
 */
export const isIosWebView = (navigator: NavigatorSubtype): boolean => {
  const safari = /safari/.test(navigator.userAgent.toLowerCase());
  return isIosDevice(navigator) && !safari;
};

/**
 * Check if we are in a Chrome browser.
 * This test is only valid on a iOS device
 */
export const isIosChrome = (userAgent: string): boolean => userAgent.includes('CriOS');

/**
 * Check if we are using Safari.
 * This test is only valid on a iOS device.
 */
export const isIosSafari = (userAgent: string): boolean => {
  // This is true when using Safari on a iOS device, but not for iPad iOS 13
  const isSafari = /version\/([\w.]+).+?mobile\/\w+\s(safari)/i.test(userAgent);
  // This is true for Safari on iPad iOS 13.
  const isSafariOnIpadV13 = !/iPad|iPhone|iPod/.test(userAgent);

  return isSafari || isSafariOnIpadV13;
};
