import gbFlag from 'src/assets/images/flags/gb.svg';
import seFlag from 'src/assets/images/flags/se.svg';
import fiFlag from 'src/assets/images/flags/fi.svg';
import { fiFI, svSE, enUS } from '@mui/material/locale';

export const localeLS = 'nordfin-locale';
export const STORAGE_KEY = 'nordfinAuth';
export const TTL_KEY = STORAGE_KEY + '_ttl';

export const locales = [
  { value: 'en', flag: gbFlag, material: enUS },
  { value: 'sv', flag: seFlag, material: svSE },
  { value: 'fi', flag: fiFlag, material: fiFI },
];
