import { Alert, Box, Snackbar } from '@mui/material';
import { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { getInvoicePDF } from 'src/api/invoices';
import { GTM } from 'src/utils/gtm';

export const DownloadPDF = forwardRef(
  (
    props: {
      children: any;
      invoiceNumber: string;
      clientId: string;
    },
    ref
  ) => {
    const { children, invoiceNumber, clientId, ...rest } = props;
    const inputRef = useRef<HTMLAnchorElement>(null);
    const [showErrorMessage, setShowError] = useState(false);
    const { t } = useTranslation();

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setShowError(false);
    };

    const pdfMutation = useMutation(
      ({ invoiceNumber, clientId }: { invoiceNumber: string; clientId: string }) =>
        getInvoicePDF(clientId, invoiceNumber),
      {
        onSuccess: (res: { fileBase64: string; fileName: string }) => {
          const base64PDF = 'data:application/octet-stream;base64,' + res.fileBase64;
          if (inputRef.current) {
            inputRef.current.setAttribute('href', base64PDF);
            inputRef.current.setAttribute('download', res.fileName);
            inputRef.current.click();
          }
        },

        onError: (_, variables) => {
          console.clear();
          if (variables.clientId === '4') {
            window.open(
              `https://apps.informationslogistik.evry.se/EvryArchive/Default.aspx?u=svekraft&p=hqeJ4oXTXO&fid=69&fnr=${variables.invoiceNumber}`,
              '_blank'
            );
          } else if (variables.clientId === '5') {
            window.open(
              `https://apps.informationslogistik.evry.se/EvryArchive/Default.aspx?fid=70&u=Finkraft&p=7EFCGhq75BGxLrP6&Faktnr=${variables.invoiceNumber}`,
              '_blank'
            );
          } else {
            setShowError(true);
          }
        },
      }
    );

    const downloadInvoice = () => {
      pdfMutation.mutate({
        invoiceNumber,
        clientId,
      });
      GTM.downloadPDF(invoiceNumber, clientId);
    };

    return (
      <Box ref={ref} {...rest}>
        {children({ loading: pdfMutation.isLoading, downloadInvoice })}
        <Box component="a" sx={{ display: 'none' }} ref={inputRef} />
        <Snackbar
          open={showErrorMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={handleClose}
          autoHideDuration={6000}
          sx={{ maxWidth: 300 }}
        >
          <Alert severity="error" onClose={handleClose} sx={{ textAlign: 'left' }}>
            <strong>{t('error.download_pdf')}</strong>
          </Alert>
        </Snackbar>
      </Box>
    );
  }
);

DownloadPDF.displayName = 'DownloadPDF';
