import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NoInvoices({ type }: { type: 'unpaid' | 'all' }) {
  const { t } = useTranslation();
  return (
    <Alert severity="success" variant="outlined" sx={{ borderWidth: 0 }}>
      <AlertTitle>{t(`no_invoice.${type}.title`)}</AlertTitle>
      {t(`no_invoice.${type}.description`)}
    </Alert>
  );
}
