// material-ui
import { alpha, Stack, useTheme } from '@mui/material';
import { Box } from '@mui/material';

// project imports
import ProfileSection from './Profile';
import NotificationSection from './Notification';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  return (
    <Box position="fixed" top={0} left={0} zIndex={99} sx={{ width: '100%', pointerEvents: 'none' }}>
      <Box
        m={2}
        sx={{
          bgcolor: alpha(theme.palette.background.default, 0.5),
          backdropFilter: 'blur(10px)',
          display: 'inline-block',
          float: 'right',
          borderRadius: 8,
          pointerEvents: 'auto',
        }}
      >
        <Stack gap={1} direction="row" alignItems="center">
          <NotificationSection />
          <ProfileSection />
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
