import { alpha, Box } from '@mui/material';
import { Client } from 'interfaces/client';
import { useTranslation } from 'react-i18next';

interface DistributionTypeProps {
  distributionType: Client['distributionType'];
}

const DistributionType: React.FC<DistributionTypeProps> = ({ distributionType }) => {
  if (!distributionType) return null;
  const { t } = useTranslation();
  return (
    <Box
      component="span"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={3}
      px={1}
      textTransform="uppercase"
      sx={{
        backgroundColor: theme =>
          alpha(
            distributionType === 'E-INVOICE'
              ? theme.palette.success.main
              : distributionType === 'EMAIL'
              ? theme.palette.success.main
              : distributionType === 'KIVRA'
              ? theme.palette.success.main
              : distributionType === 'AG'
              ? theme.palette.success.main
              : theme.palette.orange.main,
            0.2
          ),
        color: theme =>
          distributionType === 'E-INVOICE'
            ? theme.palette.mode === 'light'
              ? theme.palette.success.dark
              : theme.palette.success.light
            : distributionType === 'EMAIL'
            ? theme.palette.mode === 'light'
              ? theme.palette.success.dark
              : theme.palette.success.light
            : distributionType === 'KIVRA'
            ? theme.palette.mode === 'light'
              ? theme.palette.success.dark
              : theme.palette.success.light
            : distributionType === 'AG'
            ? theme.palette.mode === 'light'
              ? theme.palette.success.dark
              : theme.palette.success.light
            : theme.palette.mode === 'light'
            ? theme.palette.orange.dark
            : theme.palette.orange.light,

        lineHeight: 2,
        fontWeight: 600,
        fontSize: '0.75rem',
      }}
    >
      {t(`invoice.distributionTypes.${distributionType.toLowerCase()}`)}
    </Box>
  );
};

export default DistributionType;
