import { alpha, Box } from '@mui/material';
import { Invoice } from 'interfaces/invoice';
import { useTranslation } from 'react-i18next';

export function InvoiceStatus({ status }: { status: Invoice['status'] | 'Overdue' }) {
  if (!status) return null;
  const { t } = useTranslation();
  return (
    <Box
      component="span"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={3}
      px={1}
      textTransform="uppercase"
      sx={{
        backgroundColor: theme =>
          alpha(
            status === 'Pending'
              ? theme.palette.success.main
              : status === 'Paid'
              ? theme.palette.success.main
              : status === 'Unpaid'
              ? theme.palette.info.main
              : status === 'Overdue'
              ? theme.palette.orange.main
              : theme.palette.grey[500],
            0.2
          ),
        color: theme =>
          status === 'Pending'
            ? theme.palette.mode === 'light'
              ? theme.palette.success.dark
              : theme.palette.success.light
            : status === 'Paid'
            ? theme.palette.mode === 'light'
              ? theme.palette.success.dark
              : theme.palette.success.light
            : status === 'Unpaid'
            ? theme.palette.mode === 'light'
              ? theme.palette.info.dark
              : theme.palette.info.light
            : status === 'Overdue'
            ? theme.palette.mode === 'light'
              ? theme.palette.orange.dark
              : theme.palette.orange.light
            : theme.palette.mode === 'light'
            ? theme.palette.grey[900]
            : theme.palette.grey[300],
        lineHeight: 2,
        fontWeight: 600,
        fontSize: '0.75rem',
      }}
    >
      {t(`invoice.statuses.${status.toLowerCase()}`)}
    </Box>
  );
}
