/* ------------------------------- MATERIAL UI IMPORT -------------------------------- */
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  AlertTitle,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  SelectChangeEvent,
  Chip,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Stack,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';

/* ------------------------------- PROJECT IMPORT -------------------------------- */

import { useQuery } from 'react-query';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { AutogiroRequest } from 'interfaces/invoice';
import { validateSSN } from 'src/utils/validations/ssn';
import { generateAgreement } from 'src/api/autogiro';
import { Client } from 'interfaces/client';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import AutogiroBankIDForm from 'components/auth/AutogiroBankIDForm';
import { getCustomerNumbers } from 'src/api/client';
import { PDF } from './PDF';
import { useAuthentication } from 'src/hooks/use-auth';
import { ValidBankResponse, accountNumberCheck } from 'src/utils/bankAccount';
import { GTM } from 'src/utils/gtm';

/* ------------------------CHECK MARKS STYLE--------------- */

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

/* ------------------------------- AUTOGIRO MODAL PROPS TYPE -------------------------------- */
interface AutogiroModalProps {
  client: Client;
  children: ({ onClick }: { onClick: () => void }) => ReactNode;
}

/* ------------------------------- FORM VALUES DATA TYPE -------------------------------- */
type FormValues = {
  clearingNumber: string;
  accountNumber: string;
  personalNumber: string;
  customerNumbers: string[];
  firstName?: string;
  lastName?: string;
};

/* ------------------------------- AUTOGIRO MODAL COMPONENT -------------------------------- */
const AutogiroModal: React.FC<AutogiroModalProps> = ({ client, children }: AutogiroModalProps) => {
  /* ------------------------------- VARIABLES DECLARETION -------------------------------- */
  const { t, i18n } = useTranslation();
  const { auth } = useAuthentication();
  const [formState, setFormState] = useState<'initial' | 'sign' | 'success'>('initial');
  const steps = [
    { state: 'initial', label: t('activateAutogiroModal.steps.register') },
    { state: 'sign', label: t('activateAutogiroModal.steps.sign') },
  ];
  const [bankIdChecked, setBankIdChecked] = useState(false);
  const [otherPersonNameFieldChecked, setOtherPersonNameFieldChecked] = useState(false);
  const [isTermPolicyChecked, setIsTermPolicyChecked] = useState(false);
  const [validAccoutNumber, setValidAccoutNumber] = useState(false);
  const [allFormData, setAllFormData] = useState<null | AutogiroRequest>(null);
  const [open, setOpen] = useState(false);
  const [pdfResponse, setPdfResponse] = useState<{ autogiroPdfBase64: string } | null>(null);

  /* ----------------------------------- FETCHING CUSTOMER NUMBERS ---------------------- */
  const { data: multipleCustomerNumbers } = useQuery({
    queryKey: 'multipleCustomerNumbers',
    queryFn: () => getCustomerNumbers(String(client.clientId)),
    onSuccess: res => {
      if (res?.length === 1) {
        setValue('customerNumbers', res);
      }
    },
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: client.clientId != undefined && open,
  });

  /* -------------------------------- USE FORM ------------------------------- */
  const {
    register,
    handleSubmit,
    formState: useFormState,
    reset,
    resetField,
    setValue,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      customerNumbers: [],
    },
  });
  const { errors, isDirty, isSubmitSuccessful } = useFormState;

  /* ---------------------------------- BANKID CHECK BOX EVENT HANDLER ---------------------- */
  const handleBankIdCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankIdChecked(event.target.checked);
  };

  /* ----------------------------- OTHER PERSON NAME FIELD CHECK BOX HANDLER ------------------------- */
  const handleOtherPersonNameField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherPersonNameFieldChecked(event.target.checked);
    resetField('firstName');
    resetField('lastName');
  };

  /* -------------------------------------- MODAL CLOSE ICON EVENT HANDLER ----------------------------- */
  const handleClose = () => {
    setOpen(false);
    reset();
    setBankIdChecked(false);
    setOtherPersonNameFieldChecked(false);
  };

  /* ---------------------------------- GENERATE AGREEMENT MUTATION ---------------------- */
  const { mutate, error, isLoading } = useMutation(generateAgreement, {
    onSuccess: data => {
      setPdfResponse(data);
      setFormState('sign');
    },
    onError: () => {
      setFormState('initial');
      setIsTermPolicyChecked(true);
    },
  });

  /* ---------------------------------- FORM ONSUBMIT EVENT HANDLER ---------------------- */
  const onSubmit = handleSubmit(formData => {
    const isValidAccoutNumber = accountNumberCheck(`${formData.clearingNumber}-${formData.accountNumber}`);
    if (isValidAccoutNumber) {
      const account = isValidAccoutNumber as ValidBankResponse;
      const submitFormData = {
        clientId: client.clientId,
        clientName: client.clientName,
        customerNumbers: formData.customerNumbers,
        customerBankName: account.bank,
        clearingNumber: `${formData.clearingNumber}`,
        accountNumber: `${formData.accountNumber}`,
        personalNumber: `${formData.personalNumber}`,
        customerName:
          formData.firstName && formData.lastName
            ? `${formData.firstName} ${formData.lastName}`
            : auth?.authorizationResponse.customerName ?? '',
      };
      setAllFormData(submitFormData);
      GTM.activateAutogiroNext(String(client.clientId));
      mutate(submitFormData);
    } else {
      setValidAccoutNumber(false);
    }
  });

  /* ---------------------------------- SIGNIN WITH BANK ID EVENT HANDLER ---------------------- */
  const handleBankIdSigned = () => {
    setFormState('success');
  };

  /* ---------------------------HANDLE MULTIPLE CUSTOMER SELECT FUNCTION----------------- */
  const handleMultipleCustomerSelect = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const customerNumberValue = typeof value === 'string' ? value.split(',') : value;
    setValue('customerNumbers', customerNumberValue, { shouldValidate: true });
  };

  return (
    <>
      {children({
        onClick: () => {
          setOpen(true);
          GTM.activateAutogiro(String(client.clientId));
        },
      })}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          sx: { bgcolor: theme => theme.palette.background.paper, backgroundImage: 'none' },
        }}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(2px)',
            },
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={1} pr={6} pl={3} py={2}>
          <EnergySavingsLeafOutlinedIcon
            sx={{
              opacity: 0.5,
            }}
            fontSize="medium"
          />
          <Typography
            variant="h6"
            lang={i18n.language}
            sx={{ fontSize: { xs: '16px', sm: '1.25rem' }, hyphens: 'auto' }}
          >
            {t('activateAutogiroModal.registerAutogiro')} - {client.clientName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme =>
                theme.palette.mode === 'light' ? theme.palette.neutral[500] : theme.palette.neutral[200],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {/* ---------------------------------- STEPPER SECTION ---------------------- */}
        {formState !== 'success' ? (
          <Box px={[0, 4, 8]} pt={4} pb={2}>
            <Stepper activeStep={steps.findIndex(s => s.state === formState) || 0} alternativeLabel>
              {steps.map(({ label, state }) => (
                <Step
                  key={state}
                  sx={{
                    '.MuiStepConnector-line': {
                      borderColor: theme =>
                        theme.palette.mode === 'light'
                          ? formState === state
                            ? theme.palette.coldBlue[500]
                            : theme.palette.grey[400]
                          : formState === state
                          ? theme.palette.coldBlue[400]
                          : theme.palette.divider,
                      borderWidth: 2,
                      borderRadius: 6,
                    },
                  }}
                >
                  <StepLabel slotProps={{ label: { style: { marginTop: 8 } } }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        ) : null}

        {/* ---------------------------------- DIALOG CONTENT SECTION ---------------------- */}
        {formState === 'initial' && !isLoading ? (
          <DialogContent>
            <form onSubmit={onSubmit}>
              <Stack>
                <Grid container spacing={2} rowSpacing={3}>
                  {/* ---------------------------------- CLEARING NUMBER SECTION ---------------------- */}
                  <Grid item xs={12} sm={4}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            placement="bottom-start"
                            enterTouchDelay={50}
                            leaveTouchDelay={3000}
                            title={
                              <>
                                <Typography variant="caption">
                                  {t('activateAutogiroModal.toolTip.clearingNumber.commercial')}
                                </Typography>
                                <br></br>
                                <Typography variant="caption">
                                  {t('activateAutogiroModal.toolTip.clearingNumber.handelsbank')}
                                </Typography>
                                <br></br>
                                <Typography variant="caption">
                                  {t('activateAutogiroModal.toolTip.clearingNumber.swedbank')}
                                </Typography>
                                <br></br>
                                <Typography variant="caption">
                                  {t('activateAutogiroModal.toolTip.clearingNumber.abbreviationMeaning')}
                                </Typography>
                              </>
                            }
                          >
                            <InfoIcon fontSize="large" color="primary" />
                          </Tooltip>
                        ),
                      }}
                      fullWidth
                      id="clearingNumber"
                      variant="outlined"
                      label={t('activateAutogiroModal.clearingNumber')}
                      type="number"
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      {...register('clearingNumber', {
                        required: { value: true, message: t('validations.clearingNumber.required') },
                        maxLength: { value: 5, message: t('validations.clearingNumber.max', { count: 5 }) },
                        minLength: { value: 4, message: t('validations.clearingNumber.min', { count: 4 }) },
                      })}
                      error={!!errors.clearingNumber}
                      helperText={errors.clearingNumber && errors.clearingNumber.message}
                    />
                  </Grid>
                  {/* ---------------------------------- ACCOUNT NUMBER SECTION ---------------------- */}
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      id="accountNumber"
                      variant="outlined"
                      label={t('activateAutogiroModal.accountNumber')}
                      type="number"
                      {...register('accountNumber', {
                        required: { value: true, message: t('validations.accountNumber.required') },
                        maxLength: { value: 10, message: t('validations.accountNumber.max', { count: 10 }) },
                        minLength: { value: 7, message: t('validations.accountNumber.min', { count: 7 }) },
                      })}
                      error={!!errors.accountNumber}
                      helperText={errors.accountNumber && errors.accountNumber.message}
                    />
                  </Grid>
                  {!validAccoutNumber && isDirty && isSubmitSuccessful ? (
                    <Grid item xs={12}>
                      <Alert severity="error">{t('validations.bankAccountNumber.invalid')}</Alert>
                    </Grid>
                  ) : null}
                  {/* ---------------------------------- PERSONAL NUMBER SECTION ---------------------- */}
                  <Grid item xs={12} sm={multipleCustomerNumbers?.length === 1 ? 12 : 6}>
                    <TextField
                      fullWidth
                      id="personalNumber"
                      variant="outlined"
                      label={t('activateAutogiroModal.personalNumber')}
                      type="tel"
                      {...register('personalNumber', {
                        required: { value: true, message: t('auth.validations.ssn.required') },
                        maxLength: { value: 12, message: t('auth.validations.ssn.max', { count: 12 }) },
                        minLength: { value: 12, message: t('auth.validations.ssn.min', { count: 12 }) },
                        validate: value =>
                          validateSSN(value as string) !== true
                            ? (t('auth.validations.ssn.valid') as string)
                            : undefined,
                      })}
                      error={!!errors.personalNumber}
                      helperText={
                        (errors.personalNumber && errors.personalNumber.message) ||
                        t('activateAutogiroModal.helperText.personalNumber')
                      }
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            placement="bottom-start"
                            title={
                              <>
                                <Typography variant="caption">
                                  {t('activateAutogiroModal.toolTip.personalNumber.messageOne')}
                                </Typography>
                                <br></br>
                                <Typography variant="caption">
                                  {t('activateAutogiroModal.toolTip.personalNumber.messageTwo')}
                                </Typography>
                              </>
                            }
                          >
                            <InfoIcon fontSize="large" color="primary" />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  {/* ---------------------------------- MULTIPLE CUSTOMER NUMBERAS SECTION ---------------------- */}
                  {multipleCustomerNumbers?.length === 1 ? null : (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth error={Boolean(errors.customerNumbers)}>
                        <InputLabel id="customerNumbers-label">{t('activateAutogiroModal.customerNumbers')}</InputLabel>
                        <Select
                          labelId="customerNumbers-label"
                          id="customerNumbers"
                          multiple
                          value={getValues('customerNumbers')}
                          onChange={handleMultipleCustomerSelect}
                          input={<OutlinedInput id="customerNumbers-chip" label="Customer Numbers" />}
                          MenuProps={MenuProps}
                          renderValue={selected => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map(value => (
                                <Chip sx={{ height: '23px' }} key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            inputRef: (ref: { value: any }) => {
                              if (!ref) return;
                              register('customerNumbers', {
                                required: { value: true, message: t('activateAutogiroModal.validCustomerNumber') },
                              });
                            },
                          }}
                        >
                          {multipleCustomerNumbers?.map(customerNumber => (
                            <MenuItem key={customerNumber} value={customerNumber}>
                              <Checkbox checked={getValues('customerNumbers')?.indexOf(customerNumber) > -1} />
                              <ListItemText primary={customerNumber} />
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.customerNumbers ? (
                          <FormHelperText>{errors.customerNumbers.message}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  )}
                  {/* ---------------------------------- CHECK BOX SECTION ---------------------- */}
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={otherPersonNameFieldChecked}
                          onChange={handleOtherPersonNameField}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label={<Typography variant="subtitle1">{t('activateAutogiroModal.checkBoxTitle')}</Typography>}
                    />
                  </Grid>
                </Grid>
                {/* ---------------------------------- OTHER PERSONS NAME SECTION ---------------------- */}
                {otherPersonNameFieldChecked ? (
                  <Grid container spacing={2}>
                    {/* ---------------------------------- OTHER PERSONS FIRST NAME SECTION ---------------------- */}
                    <Grid item md={6} xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="firstName"
                        variant="outlined"
                        label={t('activateAutogiroModal.otherPersonFirstName.label')}
                        type="text"
                        {...register('firstName', {
                          required: {
                            value: true,
                            message: t('activateAutogiroModal.otherPersonFirstName.requiredMessage'),
                          },
                        })}
                        error={!!errors.firstName}
                        helperText={errors && errors.firstName && errors.firstName.message}
                      />
                    </Grid>
                    {/* ---------------------------------- OTHER PERSONS LAST NAME SECTION ---------------------- */}
                    <Grid item md={6} xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="lastName"
                        variant="outlined"
                        label={t('activateAutogiroModal.otherPersonLastName.label')}
                        type="text"
                        {...register('lastName', {
                          required: {
                            value: true,
                            message: t('activateAutogiroModal.otherPersonLastName.requiredMessage'),
                          },
                        })}
                        error={!!errors.lastName}
                        helperText={errors && errors.lastName && errors.lastName.message}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Stack>
              {/* ---------------------------------- PDF GENERATE ERROR SECTION ---------------------- */}
              {error ? (
                <Alert severity="error" sx={{ mt: 2 }}>
                  <AlertTitle>{t('activateAutogiroModal.pdfGenerateError.title')}</AlertTitle>
                  {t('activateAutogiroModal.pdfGenerateError.message')}
                </Alert>
              ) : null}
              {/* ---------------------------------- NEXT BUTTON SECTION ---------------------- */}
              <Box mt={2}>
                <Button type="submit" disableElevation fullWidth size="large" variant="contained" color="primary">
                  {t('activateAutogiroModal.next')}
                </Button>
              </Box>
            </form>
          </DialogContent>
        ) : isLoading ? (
          /* ---------------------------------- CIRCULAR LOADING SECTION ---------------------- */
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress size={120} />
            <Typography p={3} variant="h5">
              {t('activateAutogiroModal.loading')}
            </Typography>
          </Box>
        ) : formState === 'sign' && !isLoading ? (
          <>
            {/* ---------------------------------- PDF SECTION ---------------------- */}
            <DialogContent sx={{ pt: 0 }}>
              {pdfResponse ? <PDF pdfFile={pdfResponse.autogiroPdfBase64} fetching={isLoading} /> : null}
            </DialogContent>
            {/* ---------------------------------- PDF SIGN IN USING BANKID SECTION  ---------------------- */}
            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {pdfResponse ? (
                <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isTermPolicyChecked}
                        checked={bankIdChecked}
                        onChange={handleBankIdCheckedChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={t('activateAutogiroModal.policy')}
                  />
                  {/* ---------------------------------- AUTOGIRO BANKID FORM COMPONENT / PDF SIGN BUTTON ---------------------- */}
                  {allFormData ? (
                    <AutogiroBankIDForm
                      data={{
                        ...allFormData,
                        userNonVisibleData: pdfResponse.autogiroPdfBase64,
                      }}
                      disabled={bankIdChecked === false ? true : false}
                      onSuccess={handleBankIdSigned}
                    />
                  ) : null}
                </Box>
              ) : null}
            </DialogActions>
          </>
        ) : formState === 'success' && !isLoading ? (
          /* ---------------------------------- AUTOGIRO PROCESS COMPLETE MESSAGE SECTION ---------------------- */
          <DialogContent>
            <Alert severity="success">
              <AlertTitle>{t('activateAutogiroModal.success.title')}</AlertTitle>
              {t('activateAutogiroModal.success.description')}
            </Alert>
          </DialogContent>
        ) : null}
      </Dialog>
    </>
  );
};

export default AutogiroModal;
