import { CircularProgress, Stack, Typography } from '@mui/material';

export function CircleLoader({ text }: { text: string }) {
  return (
    <Stack display="flex" flexDirection="column" alignItems="center" spacing={3}>
      <CircularProgress size="120px" />
      <Typography textAlign="center">{text}</Typography>
    </Stack>
  );
}
