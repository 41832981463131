import { ClientInvoices, UnpaidClientInvoices } from 'interfaces/client';
import { Invoice, InvoicePDF, SwishPaymentResponse, SwishResponse } from 'interfaces/invoice';
import { transport } from 'src/utils/transport';

interface PaginationProps {
  clientID: string;
  pageNumber: number;
  pageSize: number;
}

const apiURL = import.meta.env.VITE_API_URL;

export const InvoiceEndpoints = {
  unpaidInvoices: `${apiURL}/api/invoice/unpaid`,
  clientInvoices: (clientId: string) => `${apiURL}/api/invoice/${clientId}/invoices`,
  invoicePDF: (clientId: string, invoiceNumber: string) => `${apiURL}/api/invoice/${clientId}/${invoiceNumber}/pdf`,
  invoice: (clientId: string, invoiceNumber: string) => `${apiURL}/api/invoice/${clientId}/${invoiceNumber}`,
  swishPayment: `${apiURL}/api/swish/payment`,
  swishPaymentStatus: (invoiceNumber: string, instructionUUID: string) =>
    `${apiURL}/api/swish/payment/status/${invoiceNumber}/${instructionUUID}`,
};

export const getUnpaidInvoices = (): Promise<UnpaidClientInvoices[]> => {
  return transport.get(InvoiceEndpoints.unpaidInvoices);
};

export const getClientInvoices = ({ clientID, pageNumber, pageSize }: PaginationProps): Promise<ClientInvoices> => {
  return transport.get(
    InvoiceEndpoints.clientInvoices(clientID) + `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
  );
};

export const getInvoice = (clientId: string, invoiceNumber: string): Promise<Invoice> => {
  return transport.get(InvoiceEndpoints.invoice(clientId, invoiceNumber));
};

export const getInvoicePDF = (clientId: string, invoiceNumber: string): Promise<InvoicePDF> => {
  return transport.get(InvoiceEndpoints.invoicePDF(clientId, invoiceNumber));
};

export const swishEcommerceRequest = ({
  callbackUrl,
  payerAlias,
  amount,
  paymentReference,
}: {
  callbackUrl: string;
  payerAlias: string;
  amount: string;
  paymentReference: string;
}): Promise<SwishResponse> => {
  return transport.post(InvoiceEndpoints.swishPayment, {
    callbackUrl,
    payerAlias,
    amount,
    payeePaymentReference: paymentReference,
  });
};

export const retrivePaymentStatus = (instructionUUID: string, invoiceId: string): Promise<SwishPaymentResponse> => {
  return transport.get(InvoiceEndpoints.swishPaymentStatus(invoiceId, instructionUUID));
};
