import { ImgHTMLAttributes, SyntheticEvent } from 'react';
import ImagePlaceholder from 'src/assets/images/placeholder.svg';
type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  alt: string;
};

export function Image({ src, alt, ...rest }: ImageProps) {
  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = ImagePlaceholder;
  };

  return <img {...rest} src={src} alt={alt} onError={addImageFallback} />;
}
