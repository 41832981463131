import { Box, Skeleton, Typography } from '@mui/material';
import NoClients from 'components/Dashboard/NoClients';
import { useTranslation } from 'react-i18next';
import Statistics from 'components/Dashboard/Statistics';
import UnpaidInvoices from 'components/Dashboard/UnpaidInvoices';
import { Client, UnpaidClientInvoices } from 'interfaces/client';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getUnpaidInvoices } from 'src/api/invoices';
import { isNullOrUndefined } from 'src/utils/isNullOrUndefined';
import { convertToNumber } from 'src/utils/currency';

interface Stats {
  invoices: number;
  totalAmount: number;
  nextDueDate: string;
  countryCode: Client['countryCode'];
}

export default function Dashboard() {
  const { t } = useTranslation();
  const [refetchCount, setRefetchCount] = useState(0);
  const [statistics, setStatistics] = useState<Stats>({
    invoices: -1,
    totalAmount: -1,
    nextDueDate: '',
    countryCode: 'SE',
  });
  const {
    data: clients,
    isSuccess: invoicesSuccess,
    isLoading: invoicesLoading,
  } = useQuery(['invoices', refetchCount], () => getUnpaidInvoices(), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (clients) {
      setStatistics(calcInvoicesStats(clients));
    }
  }, [clients]);

  return (
    <Box paddingTop={[8, 4]}>
      <Typography variant="h4" fontWeight="bold" paddingBottom={2} data-testid="dashboard-title">
        {t('page.dashboard.title')}
      </Typography>
      {invoicesLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }} flexDirection="column" gap={1}>
          <Skeleton
            sx={{
              opacity: 0.5,
              width: '100%',
              transform: 'scale(1)',
              mb: 1,
            }}
            height={80}
            animation="wave"
          />
          <Skeleton
            sx={{
              opacity: 0.5,
              width: '100%',
              transform: 'scale(1)',
            }}
            height={60}
            animation="wave"
          />
          <Skeleton
            sx={{
              opacity: 0.5,
              width: '100%',
              transform: 'scale(1)',
            }}
            height={75}
            animation="wave"
          />
        </Box>
      ) : invoicesSuccess && clients && clients.length > 0 ? (
        <>
          <Statistics data={statistics} />
          <UnpaidInvoices clients={clients} refetch={() => setRefetchCount(prev => prev + 1)} />
        </>
      ) : (
        <NoClients />
      )}
    </Box>
  );
}

function calcInvoicesStats(clients: UnpaidClientInvoices[]): Stats {
  let invoices = 0;
  let totalAmount = 0;
  let countryCode = '' as Client['countryCode'];
  const allDueDates = [] as string[];
  clients.forEach(client => {
    invoices += client.unpaidInvoices.length;
    countryCode = client.clientResponse.countryCode;
    client.unpaidInvoices.forEach(invoice => {
      if (!isNullOrUndefined(invoice.remainingAmount)) {
        const value =
          typeof invoice.remainingAmount === 'number'
            ? invoice.remainingAmount
            : convertToNumber(invoice.remainingAmount);
        totalAmount += value;
      }
      allDueDates.push(invoice.dueDate);
    });
  });
  const nextDueDate = getClosestDateToToday(allDueDates);
  return { invoices, totalAmount, nextDueDate, countryCode };
}

function parseYMD(s: string) {
  const b = s.split(/\D/);
  return new Date(Number(b[0]), Number(b[1]) - 1, Number(b[2]));
}

function getClosestDateToToday(arr: string[]) {
  const now = new Date();
  now.setHours(0, 0, 0);
  return arr.reduce((acc: any, s) => {
    const d = parseYMD(s);
    d.setMinutes(1);
    return d.getTime() < now.getTime() ? acc : acc && d.getTime() > parseYMD(acc).getTime() ? acc : s;
  }, null);
}
