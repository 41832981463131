import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { themeOptions } from './theme';
import { AuthenticationProvider } from './hooks/use-auth';
import { authenticate, signOut } from 'src/api/auth';
import { useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { useDarkMode } from './hooks/use-theme-mode';
import { GTM } from './utils/gtm';
import { useTranslation } from 'react-i18next';
import { STORAGE_KEY, TTL_KEY, localeLS, locales } from './utils/enums';
import { sentry } from './utils/sentry';
import { RejectError } from './utils/transport';

function App() {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: e => {
        const error = e as RejectError;
        // Unauthorized
        if (error?.response?.status === 401) {
          signOut();
          localStorage.removeItem(STORAGE_KEY);
          localStorage.removeItem(TTL_KEY);
          window.location.reload();
        }
      },
    }),
  });
  const { isDarkMode } = useDarkMode();
  const { i18n } = useTranslation();

  useEffect(() => {
    GTM.init();
  }, []);

  const currentLanguage = localStorage.getItem(localeLS);
  if (currentLanguage && i18n.language !== currentLanguage) {
    i18n.changeLanguage(currentLanguage);
    sentry.setLocale(currentLanguage);
  }

  const theme = useMemo(
    () =>
      createTheme(
        themeOptions(isDarkMode ? 'dark' : 'light'),
        locales.find(({ value }) => value === i18n.language)?.material ?? {}
      ),
    [isDarkMode, i18n.language]
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider authenticate={authenticate} signOut={signOut}>
            <RouterProvider router={router} />
          </AuthenticationProvider>
          <CssBaseline />
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
