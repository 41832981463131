import { Box, Dialog, DialogActions, DialogContent, DialogProps, Divider, IconButton, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

interface CustomDialogProps extends DialogProps {
  titleIcon?: any;
  title: string;
  onClose: () => void;
  open: boolean;
  actions?: ReactNode;
  children: ReactNode | ReactNode[];
}

export function CustomDialog({ open, onClose, titleIcon, title, actions, children, ...rest }: CustomDialogProps) {
  const { i18n } = useTranslation();
  const Icon: any = titleIcon;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{
        sx: { bgcolor: theme => theme.palette.background.paper, backgroundImage: 'none' },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(2px)',
          },
        },
      }}
      {...rest}
    >
      <Box display="flex" alignItems="center" gap={1} pr={6} pl={3} py={2}>
        {titleIcon ? (
          <Icon
            sx={{
              opacity: 0.5,
            }}
            fontSize="medium"
          />
        ) : null}
        <Typography variant="h6" lang={i18n.language} sx={{ fontSize: { xs: '16px', sm: '1.25rem' }, hyphens: 'auto' }}>
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => (theme.palette.mode === 'light' ? theme.palette.neutral[500] : theme.palette.neutral[200]),
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <DialogContent>{children}</DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  );
}
