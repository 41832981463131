interface Scale {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    coldBlue: Scale;
    green: Scale;
    neutral: Scale;
    orange: {
      main: string;
      dark: string;
      light: string;
    };
  }

  interface PaletteOptions {
    coldBlue?: Scale;
    green?: Scale;
    neutral?: Scale;
    orange?: {
      main: string;
      dark: string;
      light: string;
    };
  }
}

const green = {
  50: '#f4f9fb',
  100: '#e8f1f6',
  200: '#cce3eb',
  300: '#88c0d0',
  400: '#6cb2c4',
  500: '#4a98ad',
  600: '#387c91',
  700: '#2e6376',
  800: '#295563',
  900: '#274753',
};

const coldBlue = {
  50: '#f4f9fb',
  100: '#e8f1f6',
  200: '#cce3eb',
  300: '#88c0d0',
  400: '#6cb2c4',
  500: '#4a98ad',
  600: '#387c91',
  700: '#2e6376',
  800: '#295563',
  900: '#274753',
};

const neutral = {
  '50': '#f8f8f8',
  '100': '#f0f0f0',
  '200': '#e4e4e4',
  '300': '#d1d1d1',
  '400': '#b4b4b4',
  '500': '#9a9a9a',
  '600': '#878787',
  '700': '#6a6a6a',
  '800': '#5a5a5a',
  '900': '#4e4e4e',
};

const grey = {
  100: '#fbfbfc',
  200: '#f7f9fb',
  300: '#f4f6f9',
  400: '#d8dee9',
  500: '#DDDDDD',
  600: '#3b4252',
  700: '#39404f',
  800: '#373d4c',
  900: '#2e3440',
};

export const themePalette = (mode: 'light' | 'dark' = 'light') => ({
  mode: mode,
  background: {
    default: mode === 'light' ? grey[300] : grey[900],
    paper: mode === 'light' ? grey[100] : grey[700],
  },
  primary: {
    main: mode === 'light' ? green[500] : green[400],
  },
  secondary: {
    main: coldBlue[400],
  },
  grey,
  orange: {
    main: '#f2c097',
    light: '#fbead9',
    dark: '#712d1d',
  },
  green,
  coldBlue,
  neutral,
});
