import { Tooltip } from '@mui/material';
import { formatDistance, formatRelative, isToday, format } from 'date-fns';
import { enGB, fi, sv } from 'date-fns/locale';
import { Locales } from 'interfaces/locale';
import { useTranslation } from 'react-i18next';

function parseYMD(s: string) {
  const b = s.split(/\D/);
  return new Date(Number(b[0]), Number(b[1]) - 1, Number(b[2]));
}

const locale = {
  sv: sv,
  fi: fi,
  en: enGB,
};

export function DateField({ toDate }: { toDate: string }) {
  const { i18n } = useTranslation();
  const d = new Date(parseYMD(toDate));
  const today = new Date();
  today.setHours(0, 0, 0);
  const isDateToday = isToday(d);
  const formatedDate = formatDistance(d, today, {
    locale: locale[i18n.language as Locales],
    addSuffix: true,
  });

  const formatedDateRelative = formatRelative(d, today, {
    locale: locale[i18n.language as Locales],
  });

  return (
    <Tooltip title={toDate} placeholder="top" enterTouchDelay={50} leaveTouchDelay={3000}>
      <span>{isDateToday ? formatedDateRelative : formatedDate}</span>
    </Tooltip>
  );
}

export function DateFormat({ toDate }: { toDate: string }) {
  const { i18n } = useTranslation();
  const d = new Date(parseYMD(toDate));
  const formatedDate = format(d, 'do MMMM yyyy', {
    locale: locale[i18n.language as Locales],
  });

  return (
    <Tooltip title={toDate} placeholder="top" enterTouchDelay={50} leaveTouchDelay={3000}>
      <span>{formatedDate}</span>
    </Tooltip>
  );
}
