import { Box, Skeleton } from '@mui/material';
import Invoices from 'components/Invoice/Invoices';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientInvoices } from 'src/api/invoices';
import { useState } from 'react';
import { Client } from 'interfaces/client';

export default function ClientInvoices({ client }: { client?: Client }) {
  const { clientId } = useParams();
  const [refetchCount, setRefetchCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const cid = clientId as string;
  const { data, isLoading, isFetching, isSuccess } = useQuery({
    queryKey: ['clientInvoices', pageNumber, pageSize, refetchCount],
    queryFn: () => getClientInvoices({ clientID: cid, pageNumber, pageSize }),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: clientId != undefined,
  });

  return isLoading || isFetching ? (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} flexDirection="column" mx={1}>
      <Skeleton
        sx={{
          opacity: 0.5,
          width: '100%',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          transform: 'scale(1)',
          mb: 1,
        }}
        height={60}
        animation="wave"
      />
      <Skeleton
        sx={{
          opacity: 0.5,
          width: '100%',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          transform: 'scale(1)',
          mb: 1,
        }}
        height={60}
        animation="wave"
      />
    </Box>
  ) : isSuccess && client ? (
    <Invoices
      clientId={client.clientId}
      invoices={data.invoices}
      totalInvoices={data.totalNrOfInvoices}
      pagination={{ pageNumber, setPageNumber, pageSize, setPageSize }}
      rowsOptions={[10, 20, 50, 100]}
      type="all"
      refetch={() => setRefetchCount(prev => prev + 1)}
    />
  ) : null;
}
