import { IconButton, Tooltip, Typography, Zoom } from '@mui/material';
import { Client } from 'interfaces/client';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import { useTranslation } from 'react-i18next';
import AutogiroModal from 'components/Invoice/AutogiroModal';

export function AutogiroStatus({ client, size = 'medium' }: { client: Client; size?: 'medium' | 'large' }) {
  const { t } = useTranslation();
  if (client.isAutogiro) {
    return (
      <AutogiroModal client={client}>
        {({ onClick }) => (
          <IconButton
            {...{
              onClick: ['inactive', 'denied', 'cancelled'].some(status => status === client.autogiroStatus)
                ? onClick
                : () => undefined,
            }}
          >
            <Zoom in={client.isAutogiro}>
              <Tooltip
                title={
                  <Typography fontSize={16}>
                    {t(client.autogiroStatus ? `client.autogiro_${client.autogiroStatus}` : 'client.autogiro_inactive')}
                  </Typography>
                }
                enterTouchDelay={50}
                leaveTouchDelay={3000}
                placement="top"
              >
                <EnergySavingsLeafOutlinedIcon
                  fontSize={size}
                  color={
                    client.autogiroStatus === 'active'
                      ? 'success'
                      : client.autogiroStatus === 'pending'
                      ? 'info'
                      : client.autogiroStatus === 'cancelled' || client.autogiroStatus === 'denied'
                      ? 'warning'
                      : 'disabled'
                  }
                />
              </Tooltip>
            </Zoom>
          </IconButton>
        )}
      </AutogiroModal>
    );
  }

  return null;
}
