import { Box, Button, Typography } from '@mui/material';
import { UnpaidClientInvoices } from 'interfaces/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Invoices from 'components/Invoice/Invoices';
import { CustomCard } from 'components/CustomCard';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { useState } from 'react';
import { AutogiroStatus } from 'components/Client/AutogiroStatus';
import { GTM } from 'src/utils/gtm';

export default function UnpaidInvoices({ clients, refetch }: { clients: UnpaidClientInvoices[]; refetch: () => void }) {
  return (
    <>
      {clients
        .sort((a, b) => b.unpaidInvoices.length - a.unpaidInvoices.length)
        .map(client => (
          <Client client={client} refetch={refetch} key={client.clientResponse.clientId} />
        ))}
    </>
  );
}

function Client({ client, refetch }: { client: UnpaidClientInvoices; refetch: () => void }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  return (
    <Box mb={2} key={client.clientResponse.clientId}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography
            variant="h6"
            color={theme => (theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[300])}
          >
            {client.clientResponse.clientName}
          </Typography>
          <AutogiroStatus client={client.clientResponse} />
        </Box>
        <Button
          variant="text"
          color="primary"
          endIcon={<KeyboardDoubleArrowRightOutlinedIcon fontSize="large" color="primary" />}
          onClick={() => {
            navigate(`/${client.clientResponse.clientId}`);
            GTM.clientPageButton(String(client.clientResponse.clientId), String(client.clientResponse.clientName));
          }}
        >
          {t('client.more_info')}
        </Button>
      </Box>
      <CustomCard sx={{ overflow: ['scroll hidden', 'auto'] }}>
        <Invoices
          clientId={client.clientResponse.clientId}
          totalInvoices={client.unpaidInvoices.length}
          invoices={client.unpaidInvoices.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize)}
          {...(client.unpaidInvoices.length > pageSize
            ? { pagination: { pageNumber, setPageNumber, pageSize, setPageSize } }
            : {})}
          type="unpaid"
          refetch={refetch}
        />
      </CustomCard>
    </Box>
  );
}
