import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import { Divider, Grid, Typography } from '@mui/material';

// project imports
import AuthWrapper from 'components/auth/AuthWrapper';
import AuthCardWrapper from 'components/auth/AuthCardWrapper';
import ForgotPasswordForm from 'components/auth/ForgotPassword';
import Logo from 'src/layouts/Logo';

// ===============================|| AUTH - REGISTER ||=============================== //

const ForgotPassword = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 1 }}>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color={theme.palette.mode === 'light' ? theme.palette.neutral[700] : theme.palette.neutral[300]}
                      gutterBottom
                      variant="h5"
                      textAlign="center"
                    >
                      {t('auth.forgot_password_title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ForgotPasswordForm />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="column" alignItems="center" xs={12}>
                      <Typography
                        component={Link}
                        to="/"
                        variant="subtitle1"
                        color="primary"
                        sx={{ textDecoration: 'none' }}
                      >
                        {t('auth.back_to_sign_in')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ForgotPassword;
