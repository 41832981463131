import { useForm, SubmitHandler } from 'react-hook-form';

import { Alert, AlertTitle, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { forgotPassword } from 'src/api/auth';
import { CircleLoader } from 'components/CircleLoader';
import { GTM } from 'src/utils/gtm';

// ===========================|| FORGOT PASSWORD ||=========================== //

type Inputs = {
  email: string;
};

const ForgotPasswordForm = ({ ...others }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [formState, setFormState] = useState<'initial' | 'submitting' | 'success'>('initial');

  const forgotPasswordMutation = useMutation(forgotPassword, {
    onSuccess: data => {
      if (data.code === 10006) {
        setError(t('auth.errors.email_not_existing'));
        setFormState('initial');
      } else {
        setFormState('success');
        GTM.forgotPasswordSendEmaiAction({ action: 'success' });
      }
    },
    onError: () => {
      setError(t('auth.errors.invalid_email'));
      setFormState('initial');
      GTM.forgotPasswordSendEmaiAction({ action: 'error' });
    },
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    GTM.forgotPasswordSendEmailButton();
    forgotPasswordMutation.mutate({
      email: data.email,
      mailContext: {
        mailSubject: t('mail.reset_password.mailSubject'),
        linkText: t('mail.reset_password.resetText'),
        descriptionText: t('mail.reset_password.descriptionText'),
        gdprText: t('mail.gdprText'),
        supportButton: t('mail.supportButton'),
        contact: t('mail.contact'),
      },
    });
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)} {...others}>
        {formState === 'initial' ? (
          <>
            <Typography variant="body1" pb={4}>
              {t('auth.forgot_password_message')}
            </Typography>
            <Stack spacing={1}>
              <TextField
                fullWidth
                label={t('auth.fields.email')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                {...register('email', {
                  required: {
                    value: true,
                    message: t('auth.validations.email.required'),
                  },
                  validate: value =>
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                      value
                    ) !== true
                      ? (t('auth.validations.email.valid') as string)
                      : undefined,
                })}
                type="email"
              />
              {error !== null ? <Alert severity="error">{error}</Alert> : null}
            </Stack>
            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('auth.send')}
              </Button>
            </Box>
          </>
        ) : formState === 'submitting' ? (
          <CircleLoader text={t('auth.submitting')} />
        ) : formState === 'success' ? (
          <Alert severity="success">
            <AlertTitle>{t('auth.forgot_password.success.title')}</AlertTitle>
            {t('auth.forgot_password.success.description')}
          </Alert>
        ) : null}
      </form>
    </>
  );
};

export default ForgotPasswordForm;
