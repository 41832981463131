/* ------------------------------- MATERIAL UI IMPORT -------------------------------- */
import { Alert, Box, Divider, Tooltip, Typography, Chip, Button, Hidden } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

/* ------------------------------- PROJECT IMPORT -------------------------------- */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCustomerInfo } from 'src/api/customer';
import { useQuery } from 'react-query';
import { CircleLoader } from 'components/CircleLoader';
import { GTM } from 'src/utils/gtm';
import { CustomDialog } from 'components/CustomDialog';

/* ------------------------------- CUSTOMER DETAILS COMPONENT -------------------------------- */
const CustomerDetails = ({ clientName, clientId }: { clientName: string; clientId: number }) => {
  /* ------------------------------- VARIABLES DECLARETION -------------------------------- */
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  /* ----------------------------------- FETCHING CUSTOMER ---------------------- */
  const { data: customerInformation, isLoading } = useQuery({
    queryKey: 'customerInfo',
    queryFn: () => getCustomerInfo(String(clientId)),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: clientId != undefined && dialogOpen,
  });

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Tooltip
        title={<Typography fontSize={16}>{t('client.customerDeatils.title')}</Typography>}
        enterTouchDelay={50}
        leaveTouchDelay={3000}
        placement="top"
      >
        <Button
          variant="text"
          onClick={() => {
            setDialogOpen(true);
            GTM.myInfoButton(String(clientId));
          }}
          endIcon={<InfoIcon />}
        >
          <Hidden only="xs">{t('client.customerDeatils.button')}</Hidden>
        </Button>
      </Tooltip>
      <CustomDialog
        onClose={handleClose}
        open={dialogOpen}
        titleIcon={InfoIcon}
        title={t('client.customerDeatils.title')}
        maxWidth="sm"
      >
        {/* RENDERING CUSTOMER INFORMATION BASED ON CORRESPONDING QUERIES */}
        {isLoading ? (
          <CircleLoader text={t('ui.loading')} />
        ) : (
          customerInformation?.map(data => (
            <Box key={data.customerNumber}>
              <span style={{ fontWeight: '500', marginRight: '12px' }}>
                {t('client.customerDeatils.customerNumber')}
              </span>
              <Chip color="primary" size="small" label={data.customerNumber} variant="filled" />
              <Typography>
                <span style={{ fontWeight: '500', marginRight: '8px' }}>{t('client.customerDeatils.address')}</span>
                {data.customerAddress}, {data.customerAddress2} {data.customerAddress2 ? ',' : null}{' '}
                {data.customerPostalCode} {data.customerCity}, {data.customerLand}
              </Typography>

              <Typography>
                <span style={{ fontWeight: '500', marginRight: '8px' }}>{t('client.customerDeatils.email')}</span>{' '}
                {data.customerEmail}
              </Typography>

              <Typography>
                <span style={{ fontWeight: '500' }}>{t('client.customerDeatils.telephone')}</span> {data.customerPhone}
              </Typography>
              <Divider sx={{ mb: 1, mt: 1 }} />
            </Box>
          ))
        )}
        <Alert severity="info" sx={{ mt: 1, mb: 0 }}>
          {t('client.customerDeatils.contact')} <b>{clientName}</b> {t('client.customerDeatils.toUpdate')}
        </Alert>
      </CustomDialog>
    </>
  );
};

export default CustomerDetails;
