import { Box, Button, ButtonProps, Card, Grid, Typography } from '@mui/material';
import DistributionType from 'components/Invoice/DistributionType';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import { useTranslation } from 'react-i18next';
import { Client } from 'interfaces/client';
import { useEffect, useState } from 'react';
import { CustomDialog } from 'components/CustomDialog';

const disabledUntilDone = true;

export function DistributionTypeSelection({
  defaultType,
  showCurrent = false,
  buttonProps,
  showIcon = true,
}: {
  defaultType: Client['distributionType'];
  showCurrent?: boolean;
  showIcon?: boolean;
  buttonProps?: ButtonProps;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selected, setSelected] = useState<Client['distributionType'] | null>(null);
  const { t } = useTranslation();
  if (!defaultType) return null;
  const distributionTypes = [
    {
      value: 'AG',
      label: t(`invoice.distributionTypes.ag`),
      description: t(`client.distribution.description.ag`),
      disabled: true,
      level: 2,
      eco: 'green',
    },
    {
      value: 'E-INVOICE',
      label: t(`invoice.distributionTypes.e-invoice`),
      description: t(`client.distribution.description.e-invoice`),
      disabled: true,
      level: 2,
      eco: 'green',
    },
    {
      value: 'KIVRA',
      label: t(`invoice.distributionTypes.kivra`),
      description: t(`client.distribution.description.kivra`),
      disabled: true,
      level: 2,
      eco: 'green',
    },
    {
      value: 'EMAIL',
      label: t(`invoice.distributionTypes.email`),
      description: t(`client.distribution.description.email`),
      disabled: false,
      level: 1,
      eco: 'green',
    },
    {
      value: 'PRINT',
      label: t(`invoice.distributionTypes.print`),
      description: t(`client.distribution.description.print`),
      disabled: false,
      level: 0,
      eco: 'yellow',
    },
  ] as Array<{
    value: Client['distributionType'];
    label: string;
    description: string;
    disabled: boolean;
    level: number;
    eco: 'green' | 'yellow';
  }>;

  useEffect(() => {
    setSelected(defaultType);
  }, [defaultType]);

  const onClose = () => {
    setDialogOpen(false);
    setSelected(defaultType);
  };
  const submit = () => setDialogOpen(false);

  return (
    <>
      {showCurrent ? (
        <Box display="flex" justifyContent="flex-end" flexDirection="row" mb={disabledUntilDone ? 0 : 1}>
          <Typography pr={1}>{t('invoice.distributionType')}:</Typography>{' '}
          <DistributionType distributionType={defaultType} />
        </Box>
      ) : null}
      {/* DISABLED UNTIL BACKEND IS DONE */}
      {disabledUntilDone ? null : (
        <Button
          variant="contained"
          {...buttonProps}
          onClick={() => setDialogOpen(true)}
          endIcon={showIcon ? <DonutSmallOutlinedIcon /> : null}
        >
          {t('client.distribution.button.change')}
        </Button>
      )}
      <CustomDialog
        onClose={onClose}
        open={dialogOpen}
        titleIcon={DonutSmallOutlinedIcon}
        title={t('client.distribution.dialog_title')}
        actions={
          <>
            <Button type="button" onClick={onClose}>
              {t('ui.close')}
            </Button>
            <Button variant="contained" type="button" disabled={selected === defaultType} onClick={submit}>
              {t('ui.save')}
            </Button>
          </>
        }
      >
        <Grid container spacing={2}>
          {distributionTypes.map(type => (
            <Grid item xs={12} sm={6} key={type.value}>
              <Card
                variant="outlined"
                radioGroup="distributionType"
                onClick={() =>
                  type.disabled || type.level < (distributionTypes.find(t => t.value === defaultType)?.level || 0)
                    ? null
                    : setSelected(type.value)
                }
                sx={{
                  height: '100%',
                  py: 2,
                  px: 2,
                  ':hover': {
                    backgroundColor: theme =>
                      theme.palette.mode === 'light' ? theme.palette.grey['300'] : theme.palette.grey['900'],
                  },
                  backgroundColor: theme =>
                    theme.palette.mode === 'light' ? theme.palette.grey['200'] : theme.palette.grey['800'],
                  opacity: type.level < (distributionTypes.find(t => t.value === defaultType)?.level || 0) ? 0.5 : 1,
                  cursor: type.disabled
                    ? 'help'
                    : type.level < (distributionTypes.find(t => t.value === defaultType)?.level || 0)
                    ? 'not-allowed'
                    : 'pointer',
                  borderRadius: 2,
                  boxShadow: theme =>
                    type.value === selected
                      ? `0 0 0 2px ${
                          theme.palette.mode === 'light' ? theme.palette.coldBlue['500'] : theme.palette.coldBlue['300']
                        }`
                      : 'none',
                  borderColor: theme =>
                    type.value === selected
                      ? theme.palette.coldBlue['500']
                      : theme.palette.mode === 'light'
                      ? theme.palette.grey['400']
                      : theme.palette.neutral[700],
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography fontWeight="bold">{type.label}</Typography>
                    <Typography>{type.description}</Typography>
                  </Box>
                  <Box>
                    {type.eco === 'green' ? (
                      <EnergySavingsLeafOutlinedIcon color="success" />
                    ) : (
                      <TimelapseOutlinedIcon sx={{ fill: theme => theme.palette.orange.main }} />
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CustomDialog>
    </>
  );
}
