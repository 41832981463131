import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { InvoiceStatus } from 'components/Invoice/Status';
import { Client } from 'interfaces/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { setCurrency } from 'src/utils/currency';
import NoInvoices from 'components/Dashboard/NoInvoices';
import { DownloadPDF } from './DownloadPDF';
import { overdue } from 'src/utils/date';
import { Invoice } from 'interfaces/invoice';
import { useTranslation } from 'react-i18next';
import { DateField, DateFormat } from 'components/DateField';
import SwishModal from './SwishModal';
import SwishLogo from 'src/assets/images/SwishLogo.svg';
import { GTM } from 'src/utils/gtm';

const tableSize = 'small';

export default function Invoices({
  clientId,
  invoices,
  totalInvoices,
  pagination,
  rowsOptions = [5, 10, 25, 100],
  type,
  refetch,
}: {
  clientId: Client['clientId'];
  invoices: Invoice[];
  totalInvoices?: number;
  pagination?: {
    pageNumber: number;
    setPageNumber: Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    setPageSize: Dispatch<React.SetStateAction<number>>;
  };
  rowsOptions?: number[];
  type: 'unpaid' | 'all';
  refetch: () => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleChangePage = (event: unknown, newPage: number) => {
    pagination?.setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination?.setPageSize(+event.target.value);
    pagination?.setPageNumber(0);
  };

  if (invoices.length === 0) {
    return <NoInvoices type={type} />;
  }

  return (
    <>
      <Table
        sx={{
          borderRadius: 1,
          px: 2,
        }}
        size={tableSize}
      >
        <TableBody>
          {invoices.map(invoice => (
            <TableRow key={invoice.invoiceNumber}>
              <TableCell size={tableSize} sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                <Typography variant="subtitle2">#{invoice.invoiceNumber}</Typography>
              </TableCell>
              <TableCell size={tableSize}>
                <Typography variant="subtitle2" whiteSpace="nowrap">
                  {setCurrency(invoice.remainingAmount, invoice.clientLand)}
                </Typography>
                <Typography variant="body2" color="GrayText">
                  {setCurrency(invoice.invoiceAmount, invoice.clientLand)}
                </Typography>
              </TableCell>
              <TableCell size={tableSize} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                <Typography variant="subtitle2">{t('invoices.issued')}</Typography>
                <Typography variant="body2">
                  <DateFormat toDate={invoice.billDate} />
                </Typography>
              </TableCell>
              <TableCell size={tableSize}>
                <Typography variant="subtitle2">{t('invoices.due')}</Typography>
                <Typography variant="body2">
                  <DateField toDate={invoice.dueDate} />
                </Typography>
              </TableCell>
              <TableCell size={tableSize}>
                <InvoiceStatus
                  status={invoice.status === 'Unpaid' && overdue(invoice.dueDate) ? 'Overdue' : invoice.status}
                />
              </TableCell>
              <TableCell size={tableSize} align="right">
                <Box display="flex" flexDirection={['column', 'row']} alignItems="center" justifyContent="flex-end">
                  {invoice.status === 'Unpaid' ? (
                    <SwishModal invoice={invoice} callback={refetch}>
                      {({ onClick }) => (
                        <Tooltip
                          title={<Typography fontSize={16}>{t('invoice.swish')}</Typography>}
                          enterTouchDelay={50}
                          leaveTouchDelay={3000}
                          placement="top"
                        >
                          <IconButton onClick={onClick}>
                            <Box
                              sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                borderRadius: 4,
                                width: '22px',
                                height: '22px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img src={SwishLogo} alt="Swish button" height="18px" />
                            </Box>
                          </IconButton>
                        </Tooltip>
                      )}
                    </SwishModal>
                  ) : null}
                  <DownloadPDF invoiceNumber={invoice.invoiceNumber} clientId={String(clientId)}>
                    {({ loading, downloadInvoice }: { loading: boolean; downloadInvoice: () => void }) => (
                      <Tooltip
                        title={<Typography fontSize={16}>{t('invoices.download_pdf')}</Typography>}
                        enterTouchDelay={50}
                        leaveTouchDelay={3000}
                        placement="top"
                      >
                        <IconButton aria-label="download" onClick={downloadInvoice}>
                          <FileDownloadOutlinedIcon color="secondary" />
                          {loading ? (
                            <CircularProgress
                              size={40}
                              sx={{
                                color: 'success',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                              }}
                            />
                          ) : null}
                        </IconButton>
                      </Tooltip>
                    )}
                  </DownloadPDF>
                  <Tooltip
                    title={<Typography fontSize={16}>{t('invoices.go_to_payment')}</Typography>}
                    enterTouchDelay={50}
                    leaveTouchDelay={3000}
                    placement="top"
                  >
                    <IconButton
                      aria-label="details"
                      onClick={() => {
                        navigate(`/${clientId}/${invoice.invoiceNumber}`);
                        GTM.goToPaymentPageButton(String(invoice.invoiceNumber));
                      }}
                    >
                      <ArrowForwardIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination ? (
        <TablePagination
          rowsPerPageOptions={rowsOptions}
          component="div"
          count={totalInvoices || invoices.length}
          rowsPerPage={pagination.pageSize}
          page={pagination.pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mx: 1 }}
        />
      ) : null}
    </>
  );
}
