import { Box } from '@mui/material';
import { ReactNode } from 'react';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper = ({ children }: { children: ReactNode }) => (
  <Box bgcolor={theme => theme.palette.background.default} display="flex" alignItems="center" minHeight="100vh">
    {children}
  </Box>
);

export default AuthWrapper;
