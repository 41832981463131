import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Divider, Grid, Stack, Typography } from '@mui/material';

// project imports
import AuthWrapper from 'components/auth/AuthWrapper';
import AuthCardWrapper from 'components/auth/AuthCardWrapper';
import AuthRegister from 'components/auth/RegisterForm';
import Logo from 'src/layouts/Logo';

// ===============================|| AUTH - REGISTER ||=============================== //

const Register = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 1 }}>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                      <Typography
                        color={theme.palette.mode === 'light' ? theme.palette.neutral[700] : theme.palette.neutral[300]}
                        gutterBottom
                        variant="h5"
                      >
                        {t('auth.register_message')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <AuthRegister />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="column" alignItems="center" xs={12}>
                      <Typography
                        component={Link}
                        to="/"
                        variant="subtitle1"
                        color="primary"
                        sx={{ textDecoration: 'none' }}
                      >
                        {t('auth.already_customer')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Register;
