import {
  Box,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Collapse,
  Alert,
  AlertTitle,
} from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ModeToggle from 'components/ModeToggle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localeLS, locales } from 'src/utils/enums';
import { useMutation } from 'react-query';
import { resetPassword } from 'src/api/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PasswordField } from 'components/auth/PasswordField';
import { CircleLoader } from 'components/CircleLoader';
import { sentry } from 'src/utils/sentry';
import { GTM } from 'src/utils/gtm';
import { CustomDialog } from 'components/CustomDialog';

export function UserSettings({ modalState }: { modalState: any[] }) {
  const [settingsModalOpen, setSettingsModalOpen] = modalState;
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const handleClose = () => {
    setSettingsModalOpen(false);
    setShowChangePassword(false);
  };

  useEffect(() => {
    sentry.setLocale(language);
  }, [language]);

  const handleLanguageChange = (_: React.MouseEvent<HTMLElement>, newLanguage: string) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem(localeLS, newLanguage);
  };

  return (
    <CustomDialog
      onClose={handleClose}
      open={settingsModalOpen}
      titleIcon={ManageAccountsOutlinedIcon}
      title={t('profile_menu.settings_modal.title')}
      maxWidth="sm"
    >
      <Box sx={{ mx: 'auto' }} display="flex" width={['100%', 400]} justifyContent="center">
        <Stack spacing={2} sx={{ width: '100%' }}>
          <ModeToggle />
          <Box>
            <Box component="label" htmlFor="language-toggle">
              <Typography variant="h6" fontSize={16}>
                {t('profile_menu.settings_modal.language')}
              </Typography>
            </Box>
            <ToggleButtonGroup
              id="language-toggle"
              color="primary"
              value={language}
              exclusive
              onChange={handleLanguageChange}
              aria-label="Language"
              fullWidth
            >
              {locales.map(locale => (
                <ToggleButton size="small" value={locale.value} key={locale.value}>
                  {locale.value.toUpperCase()}{' '}
                  <Box component="img" ml={1} borderRadius={1} width={25} src={locale.flag} alt={locale.value} />
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Box>
            <Button onClick={() => setShowChangePassword(!showChangePassword)} sx={{ mb: 1 }}>
              {t('profile_menu.settings_modal.change_password.title')}{' '}
              <LockOutlinedIcon sx={{ pl: 1 }} fontSize="medium" />
            </Button>
            <Collapse in={showChangePassword}>
              <ChangePasswordForm isOpen={showChangePassword} />
            </Collapse>
          </Box>
        </Stack>
      </Box>
    </CustomDialog>
  );
}

type Inputs = {
  password: string;
  oldPassword: string;
};

function ChangePasswordForm({ isOpen }: { isOpen: boolean }) {
  const { t } = useTranslation();
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [formState, setFormState] = useState<'initial' | 'submitting' | 'success' | 'error'>('initial');
  const form = useForm<Inputs>();

  useEffect(() => {
    if (isOpen && formState != 'success') {
      GTM.changePasswordButton();
    }
  }, [isOpen, formState]);

  const resetPasswordMutation = useMutation(resetPassword, {
    onSuccess: data => {
      if (data.code === 10007) {
        setPasswordNotMatch(true);
        setFormState('initial');
      } else {
        setFormState('success');
      }
    },
    onError: () => {
      setFormState('error');
    },
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    setFormState('submitting');
    GTM.changePasswordSaveButton();
    resetPasswordMutation.mutate({ password: data.password, oldPassword: data.oldPassword });
  };
  return (
    <Box
      component="form"
      noValidate
      onSubmit={form.handleSubmit(onSubmit)}
      bgcolor={theme => (theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800])}
      p={2}
    >
      {formState === 'initial' ? (
        <Stack spacing={1}>
          <PasswordField
            {...form}
            label={t('profile_menu.settings_modal.change_password.labels.current_password')}
            fieldName="oldPassword"
            hideLevel
          />
          <PasswordField
            {...form}
            label={t('profile_menu.settings_modal.change_password.labels.new_password')}
            fieldName="password"
          />
          {passwordNotMatch ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle>{t('auth.reset_password.error_password.title')}</AlertTitle>
              {t('auth.reset_password.error_password.description')}
            </Alert>
          ) : null}
          <Button
            disableElevation
            disabled={form.formState.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('ui.save')}
          </Button>
        </Stack>
      ) : formState === 'submitting' ? (
        <CircleLoader text={t('auth.submitting')} />
      ) : formState === 'success' ? (
        <Alert severity="success">
          <AlertTitle>{t('auth.reset_password.success.title')}</AlertTitle>
          {t('auth.reset_password.success.description')}
        </Alert>
      ) : formState === 'error' ? (
        <Alert severity="error">
          <AlertTitle>{t('auth.reset_password.error.title')}</AlertTitle>
          {t('auth.reset_password.error.description')}
        </Alert>
      ) : null}
    </Box>
  );
}
