import { Card, CardProps } from '@mui/material';
import { ReactNode } from 'react';

interface CustomCardProps extends CardProps {
  children: ReactNode | ReactNode[];
}

export function CustomCard({ children, sx, ...rest }: CustomCardProps) {
  return (
    <Card
      sx={{
        zIndex: 0,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Card>
  );
}
