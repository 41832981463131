import { Box, CircularProgress, Grid, SpeedDial, SpeedDialAction, Typography } from '@mui/material';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import { getInvoice, getInvoicePDF } from 'src/api/invoices';
import { PaymentInfo } from 'components/Invoice/PaymentInfo';
import { useTranslation } from 'react-i18next';
import { getClient } from 'src/api/client';
import { PDF } from 'components/Invoice/PDF';
import { DownloadPDF } from 'components/Invoice/DownloadPDF';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { ReactNode, useState } from 'react';
import { PrintPDF } from 'components/Invoice/PrintPDF';

export default function Invoice() {
  const { clientId, id } = useParams();
  const [refetchCount, setRefetchCount] = useState(0);
  const { t } = useTranslation();
  const cId = clientId as string;
  const invoiceId = id as string;

  const [
    { data: invoice, isLoading, isFetching },
    { data: pdfData, isFetching: isPDFFetching },
    { data: client, isLoading: isClientLoading, isFetching: isClientFetching },
  ] = useQueries([
    {
      queryKey: ['invoice', refetchCount],
      queryFn: () => getInvoice(cId, invoiceId),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!cId,
    },
    {
      queryKey: 'invoicePDF',
      queryFn: () => getInvoicePDF(cId, invoiceId),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!cId && !!invoiceId,
    },
    {
      queryKey: 'client',
      queryFn: () => getClient(cId),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!cId,
    },
  ]);

  const pdfActions: Array<{ name: string; icon: ReactNode; onClick?: () => void }> = [
    {
      name: t('invoice.downloadInvoice'),
      icon:
        invoice && client ? (
          <DownloadPDF invoiceNumber={invoice.invoiceNumber} clientId={String(client.clientId)}>
            {({ loading, downloadInvoice }: { loading: boolean; downloadInvoice: () => void }) => (
              <Box aria-label="download" onClick={downloadInvoice} display="flex" alignItems="center">
                <FileDownloadOutlinedIcon color="primary" />
                {loading ? (
                  <CircularProgress
                    size={40}
                    sx={{
                      color: 'success',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 1,
                    }}
                  />
                ) : null}
              </Box>
            )}
          </DownloadPDF>
        ) : null,
    },
    {
      name: t('invoice.printInvoice'),
      icon: pdfData ? <PrintPDF data={pdfData} /> : null,
    },
  ];

  return (
    <Box paddingTop={4}>
      <Typography variant="h4" fontWeight="bold" paddingBottom={2} data-testid="invoice-title">
        {t('page.invoice.title')}
      </Typography>
      <Breadcrumbs
        crumbs={[
          client
            ? {
                name: String(client?.clientName),
                path: String(client?.clientId),
              }
            : {
                name: 'client page',
                path: clientId,
              },
          {
            name: `invoice #${invoiceId}`,
          },
        ]}
        isLoading={isClientLoading || isClientFetching}
      />
      <Grid container spacing={2} my={2} direction={['column-reverse', 'column-reverse', 'row']} flexWrap="nowrap">
        <Grid item md={12} lg={8} position="relative">
          <PDF pdfFile={pdfData?.fileBase64} fetching={isPDFFetching} />
          {invoice && client && pdfData ? (
            <SpeedDial
              ariaLabel="PDF actions"
              sx={{ position: 'absolute', top: 25, right: 10, zIndex: 99 }}
              FabProps={{
                size: 'small',
              }}
              icon={<SpeedDialIcon />}
              direction="down"
            >
              {pdfActions.map(action => (
                <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
              ))}
            </SpeedDial>
          ) : null}
        </Grid>
        <Grid item md={12} lg={4}>
          <PaymentInfo
            isLoading={isLoading}
            isFetching={isFetching}
            refetch={() => setRefetchCount(prev => prev + 1)}
            invoice={invoice}
            client={client}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
