export interface RejectError extends Error {
  response: Response;
}

interface ResponseData extends Response {
  data: { message: string; statusText: string; code: number };
}

function createError(
  message: string,
  response: { statusText: string },
  json: { message: string; code?: number }
): RejectError {
  const error = new Error(message) as RejectError;
  const errorResponse = Object.assign(response, {
    data: {
      statusText: response.statusText,
      message: (json && json.message) || response.statusText,
      code: json.code,
    },
  }) as ResponseData;
  error.response = errorResponse;
  return error;
}

function isStringObject(text: string) {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Performs a http request
 */
export async function client(
  endpoint: string,
  { body, ...customConfig }: { body?: object; method: string },
  headers = {}
) {
  const config: RequestInit = {
    ...customConfig,
    credentials: 'include',
    headers: {
      Pragma: 'no-cache',
      'content-type': 'application/json',
      ...headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(endpoint, config);
  if (response.ok) {
    const text = await response.text();
    const json = text === '' ? {} : JSON.parse(text);
    return json;
  } else {
    const text_2 = await response.text();
    let json_1 = { message: text_2.trim() };
    if (isStringObject(text_2)) {
      json_1 = JSON.parse(text_2);
    }
    return Promise.reject(createError('Request failed with status code ' + response.status, response, json_1));
  }
}

export const transport = {
  /**
   * Performs a get request
   */
  get(endpoint: string, headers?: any) {
    return client(endpoint, { method: 'GET' }, headers);
  },

  /**
   * Performs a post request
   */
  post(endpoint: string, data: object, headers?: any) {
    return client(endpoint, { method: 'POST', body: { ...data } }, headers);
  },

  /**
   * Performs a put request
   */
  put(endpoint: string, data: object) {
    return client(endpoint, { method: 'PUT', body: { ...data } });
  },

  /**
   * Performs a patch request
   */
  patch(endpoint: string, data: object) {
    return client(endpoint, { method: 'PATCH', body: { ...data } });
  },

  /**
   * Performs a delete request
   */
  delete(endpoint: string) {
    return client(endpoint, { method: 'DELETE' });
  },
};
