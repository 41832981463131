import { Box, Button, Card, Divider, Skeleton, Stack, Typography, alpha } from '@mui/material';
import { DistributionTypeSelection } from 'components/Client/DistributionTypeSelection';
import CopyClipboard from 'components/CopyToClipboard';
import { Client } from 'interfaces/client';
import { Invoice } from 'interfaces/invoice';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getclientInfo } from 'src/api/client';
import { setCurrency } from 'src/utils/currency';
import { overdue } from 'src/utils/date';
import DistributionType from './DistributionType';
import { InvoiceStatus } from './Status';
import SwishModal from './SwishModal';
import SwishLogo from 'src/assets/images/SwishLogo.svg';
import { Image } from 'components/Image';
import AutogiroModal from './AutogiroModal';
import { TaskAltOutlined, HourglassTopOutlined } from '@mui/icons-material';

export function PaymentInfo({
  invoice,
  client,
  isLoading,
  isFetching,
  refetch,
}: {
  invoice: Invoice | undefined;
  client: Client | undefined;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
}) {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const cId = clientId as string;

  const { data: clientInfo, isLoading: isInfoLoading } = useQuery({
    queryKey: 'clientInfo',
    queryFn: () => getclientInfo(cId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <Box sx={{ position: 'sticky', top: '5px' }} display="flex" flexDirection="column">
      <Card sx={{ p: 2, mb: 2 }}>
        <Box display="flex" mb={3} justifyContent="space-between">
          {isInfoLoading ? (
            <Skeleton
              sx={{
                opacity: 0.5,
                width: '70%',
                transform: 'scale(1)',
              }}
              height={120}
              animation="wave"
            />
          ) : (
            <Box
              sx={{
                height: '70px',
                bgcolor: theme => (theme.palette.mode === 'dark' ? theme.palette.grey[100] : 'transparent'),
              }}
              borderRadius={2}
              p={2}
              mr={4}
            >
              <Image src={`data:image/png;base64,${clientInfo?.logo}`} height="100%" alt="client logo" />
            </Box>
          )}
          <Box>
            <Typography variant="h6" fontWeight="bold" align="right">
              {invoice ? setCurrency(invoice?.invoiceAmount, invoice.clientLand) : null}
            </Typography>
            <Typography variant="body2" fontWeight="bold" align="right">
              {t('invoice.invoiceFrom')} {client?.clientName}
            </Typography>
          </Box>
        </Box>
        {isLoading || isFetching ? (
          <Skeleton
            sx={{
              opacity: 0.5,
              width: '100%',
              transform: 'scale(1)',
              mt: 1,
            }}
            height={120}
            animation="wave"
          />
        ) : invoice ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontWeight={500}>{t('invoice.status')}</Typography>
              <InvoiceStatus
                status={invoice.status === 'Unpaid' && overdue(invoice.dueDate) ? 'Overdue' : invoice.status}
              />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Stack spacing={1}>
              <InfoField title={t('invoice.deliveryDate')} value={invoice?.billDate} />
              <InfoField title={t('invoice.dueDate')} value={invoice?.dueDate} />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography fontWeight={500}>{t('invoice.distributionType')}</Typography>
                {client ? <DistributionType distributionType={client.distributionType} /> : null}
              </Box>
              <DataField title={t('invoice.bankgiroNumber')} value={invoice?.bg} />
              <DataField title={t('invoice.OCRNumber')} value={invoice?.paymentReference} />
              <DataField
                title={t('invoice.remainingAmount')}
                value={invoice ? setCurrency(invoice?.remainingAmount, invoice.clientLand) : ''}
              />
            </Stack>
          </>
        ) : null}
      </Card>

      <Card sx={{ p: 2, mb: [0, 2] }}>
        <Typography mb={2} variant="h6">
          {t('invoice.actions')}
        </Typography>
        <Stack spacing={2}>
          {/* -------------------------------------SWISH PAYMENT BUTTON-------------------------------------- */}
          {invoice && invoice.status === 'Unpaid' ? (
            <SwishModal invoice={invoice} callback={refetch}>
              {({ onClick }) => (
                <Button
                  onClick={onClick}
                  disableElevation
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  endIcon={
                    <Box
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: 4,
                        width: '28px',
                        height: '28px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={SwishLogo} alt="Swish button" height="24px" />
                    </Box>
                  }
                >
                  {t('invoice.swish')}
                </Button>
              )}
            </SwishModal>
          ) : null}

          {/* -------------------------------------ACTIVATE AUTOGIRO BUTTON-------------------------------------- */}
          {client?.isAutogiro === true ? (
            <AutogiroModal client={client}>
              {({ onClick }) => {
                return client.autogiroStatus === 'active' ? (
                  <Button
                    variant="contained"
                    sx={{
                      background: theme => alpha(theme.palette.success.main, 0.2),
                      color: theme => theme.palette.success.main,
                      pointerEvents: 'none',
                      cursor: 'default',
                    }}
                    type="button"
                    size="large"
                    disableElevation
                    fullWidth
                    endIcon={<TaskAltOutlined fontSize="small" />}
                  >
                    {t(`client.autogiro_${client?.autogiroStatus}`)}
                  </Button>
                ) : client.autogiroStatus === 'pending' ? (
                  <Button
                    variant="contained"
                    sx={{
                      background: theme => alpha(theme.palette.info.main, 0.2),
                      color: theme => theme.palette.info.main,
                      pointerEvents: 'none',
                      cursor: 'default',
                    }}
                    type="button"
                    size="large"
                    disableElevation
                    fullWidth
                    endIcon={<HourglassTopOutlined fontSize="small" />}
                  >
                    {t(`client.autogiro_${client?.autogiroStatus}`)}
                  </Button>
                ) : (
                  <Button onClick={onClick} disableElevation fullWidth size="large" variant="contained" color="primary">
                    {t('invoice.autogiro')}
                  </Button>
                );
              }}
            </AutogiroModal>
          ) : null}
          {client ? (
            <DistributionTypeSelection
              defaultType={client.distributionType}
              buttonProps={{
                fullWidth: true,
                size: 'large',
              }}
              showIcon={false}
            />
          ) : null}
        </Stack>
      </Card>
    </Box>
  );
}

function DataField({ title, value }: { title: string; value: string | number | null }) {
  if (!value) return null;
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography fontWeight={500}>{title}</Typography>
        <Typography>{value}</Typography>
      </Box>
      <CopyClipboard value={value} clipboardMessage={title} />
    </Box>
  );
}

type InfoFieldProps = {
  title: string;
  value: string | number;
};

const InfoField: React.FC<InfoFieldProps> = props => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography fontWeight={500}>{props.title}</Typography>
      <Typography>{props.value}</Typography>
    </Box>
  );
};
