/**
 * Password validator for login pages
 */

// has number
const hasNumber = (number: string) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
const hasMixed = (number: string) => new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars
const hasSpecial = (number: string) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

// set color based on password strength
export const strengthColor = (count: number) => {
  if (count < 2) return { label: 'poor', color: '#f44336', value: 30 };
  if (count < 3) return { label: 'weak', color: '#ffc107', value: 40 };
  if (count < 4) return { label: 'normal', color: '#ffab91', value: 60 };
  if (count < 5) return { label: 'good', color: '#00e676', value: 80 };
  if (count < 6) return { label: 'strong', color: '#00c853', value: 100 };
  return { label: 'poor', color: '#f44336', value: 20 };
};

// password strength indicator
export const strengthIndicator = (number: string) => {
  let strengths = 0;
  if (number.length > 5) strengths += 1;
  if (number.length > 7) strengths += 1;
  if (hasNumber(number)) strengths += 1;
  if (hasSpecial(number)) strengths += 1;
  if (hasMixed(number)) strengths += 1;
  return strengths;
};
