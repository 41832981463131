import { alpha, Shadows } from '@mui/material';
import { themePalette } from './palette';

export const themeOptions = (mode: 'light' | 'dark' = 'light') => {
  const palette = themePalette(mode);
  return {
    palette,
    typography: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
    },
    shadows: [
      'none',
      palette.mode === 'light'
        ? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
        : 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px',
      ...Array(23).fill(
        palette.mode === 'light'
          ? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
          : 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px'
      ),
    ] as Shadows,
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }: { ownerState: any }) => ({
            ...(ownerState.color === 'primary' &&
              ownerState.variant === 'contained' && {
                backgroundColor: palette.mode === 'dark' ? palette.coldBlue[400] : palette.coldBlue[600],
                color: palette.mode === 'dark' ? palette.coldBlue[900] : palette.coldBlue[100],
                ':hover': {
                  borderWidth: '2px',
                  backgroundColor: palette.mode === 'dark' ? palette.coldBlue[300] : palette.coldBlue[500],
                },
              }),
            ...(ownerState.variant === 'outlined' && {
              borderWidth: '2px',
              backgroundColor: 'transparent',
              ':hover': {
                borderWidth: '2px',
              },
            }),
            ...(ownerState.variant === 'text' && {
              backgroundColor: 'transparent',
              ...(ownerState.color === 'primary' && {
                color: palette.mode === 'dark' ? palette.coldBlue[400] : palette.coldBlue[500],
              }),
              ':hover': {
                backgroundColor: alpha(palette.coldBlue[300], 0.3),
              },
            }),
          }),
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: palette.mode === 'light' ? palette.grey[100] : palette.grey[700],
            backgroundImage: 'none',
            boxShadow:
              palette.mode === 'light'
                ? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
                : 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            ':last-child': {
              td: {
                border: '0px solid',
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderColor: palette.mode === 'light' ? 'rgb(242, 244, 247)' : 'rgba(145, 158, 171, 0.24)',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            ':before': {
              backgroundColor: palette.mode === 'light' ? 'rgb(242, 244, 247)' : 'rgba(145, 158, 171, 0.24)',
            },
            '&.Mui-expanded': {
              ':before': {
                opacity: 1,
              },
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            boxShadow:
              palette.mode === 'light'
                ? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
                : 'rgb(0 0 0 / 10%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px',
          },
        },
      },
    },
  };
};
