/* ------------------------------- MATERIAL UI IMPORT -------------------------------- */
import { Alert, AlertTitle, Box, Button, useTheme } from '@mui/material';

/* ------------------------------- PROJECT IMPORT -------------------------------- */
import { useBankId } from 'src/hooks/use-bankID';
import { useForm } from 'react-hook-form';
import { BankIDPending } from './Pending';
import { useTranslation } from 'react-i18next';
import { GTM } from 'src/utils/gtm';
import { useState } from 'react';
import { collectAndSaveAgreement, signAgreement } from 'src/api/autogiro';
import bankIDImg from '../../assets/images/BankID_logo.svg';
import bankIDImgWhite from '../../assets/images/BankID_logo_white.svg';
import { openBankId } from 'src/utils/bankID';
import { AutogiroRequest } from 'interfaces/invoice';

/* ------------------------------- SSN /PERSONAL NUMBER TYPE -------------------------------- */
type Inputs = {
  ssn: string;
};

/* ------------------------------- AUTOGIRO BANKID FORM PROPS -------------------------------- */
interface AutogiroBankIDFormProps extends AutogiroRequest {
  userNonVisibleData: string;
}

/* ------------------------------- AUTOGIRO BANKID FORM COMPONENT -------------------------------- */
const AutogiroBankIDForm = ({
  data,
  disabled,
  onSuccess,
}: {
  data: AutogiroBankIDFormProps;
  disabled: boolean;
  onSuccess: () => void;
}) => {
  /* ------------------------------- FORM STATE -------------------------------- */
  const {
    formState: { isSubmitting },
  } = useForm<Inputs>();

  /* ------------------------------- VARIABLES DECLARETION -------------------------------- */
  const { t } = useTranslation();
  const theme = useTheme();
  const [bankIDFailureMessage, setBankIDFailureMessage] = useState(false);
  const [pollError, setPollError] = useState<null | string>(null);

  /* ------------------------------- SIGN WITH BANKID FUNCTION -------------------------------- */
  const [state, dispatchBankID] = useBankId({
    pollLimit: 30,
    pollWait: 1000,
    onResolved: () => {
      onSuccess();
    },
    onPollRejection: err => {
      if (err.response?.status === 500) return;
      setPollError(t(`auth.bankID.status.${err.status}.${err.hintCode}`));
    },
    effects: {
      auth(ssn, eDispatch) {
        signAgreement({ ...data, isMobile: !ssn })
          .then(res => {
            eDispatch({ type: 'resolve_auth', reference: res.orderRef });
            if (!ssn) {
              openBankId({
                autoStartToken: res.autoStartToken,
              });
            }
          })
          .catch(error => {
            eDispatch({ type: 'reject_auth', error });
            GTM.bankIDSubmit({ action: 'error', error: JSON.stringify(error) });
            setBankIDFailureMessage(true);
          });
      },
      poll(reference, eDispatch) {
        collectAndSaveAgreement({ ...data, orderRef: reference })
          .then(res => {
            if (res.status === 'complete') {
              eDispatch({ type: 'resolve_poll', progress: 'success', auth: true });
              GTM.bankIDSubmit({ action: 'success' });
            } else if (res.status === 'pending') {
              setPollError(null);
              eDispatch({ type: 'resolve_poll', progress: 'pending', status: res });
            } else {
              eDispatch({ type: 'reject_poll', error: res });
              GTM.bankIDSubmit({ action: 'error', error: res.hintCode });
            }
          })
          .catch(error => {
            eDispatch({ type: 'reject_poll', error });
            GTM.bankIDSubmit({ action: 'error', error: JSON.stringify(error) });
            setBankIDFailureMessage(true);
          });
      },
    },
  });

  return (
    <>
      {/* ------------------------------- BANKID PENDING COMPONENT -------------------------------- */}
      <BankIDPending state={state}>
        {bankIDFailureMessage ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>{t('activateAutogiroModal.signError.title')}</AlertTitle>
            {t('activateAutogiroModal.signError.message')}
          </Alert>
        ) : null}
        {pollError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {pollError}
          </Alert>
        ) : null}
        <>
          {/* ------------------------------- SIGN WITH BANKID ON SAME DEVICE BUTTON  -------------------------------- */}
          <Button
            type="button"
            disableElevation
            disabled={disabled || isSubmitting}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatchBankID({ type: 'auth' });
              GTM.bankIDSameDeviceForAutogiro();
            }}
          >
            {t('activateAutogiroModal.signWithSameDevice')}
            <img src={theme.palette.mode === 'dark' ? bankIDImg : bankIDImgWhite} height={35} alt="bankID" />
          </Button>
          {/* ------------------------------- SIGN WITH BANKID ON OTHER DEVICE BUTTON  -------------------------------- */}
          <Box sx={{ mt: 1 }}>
            <Button
              disableElevation
              disabled={disabled || isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                dispatchBankID({ type: 'auth', ssn: data.personalNumber });
                GTM.bankIDOtherDeviceForAutogiro();
              }}
            >
              {t('activateAutogiroModal.signWithOtherDevice')}
              <img src={theme.palette.mode === 'dark' ? bankIDImg : bankIDImgWhite} height={35} alt="bankID" />
            </Button>
          </Box>
        </>
      </BankIDPending>
    </>
  );
};

export default AutogiroBankIDForm;
