import { Alert, AlertTitle, Box, Divider, Link, Skeleton, Typography } from '@mui/material';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getClient, getclientInfo } from 'src/api/client';
import { CustomCard } from 'components/CustomCard';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import ClientInvoices from 'components/Client/Invoices';
import { DistributionTypeSelection } from 'components/Client/DistributionTypeSelection';
import { Image } from 'components/Image';
import { AutogiroStatus } from 'components/Client/AutogiroStatus';
import { ClientContact } from 'components/Client/Contact';
import CustomerDetails from 'components/Client/CustomerDetails';

export default function Client() {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const cid = clientId as string;
  const [{ data: client, isLoading, isFetching }, { data: clientInfo, isLoading: isInfoLoading }] = useQueries([
    {
      queryKey: ['client'],
      queryFn: () => getClient(cid),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: clientId != undefined,
    },
    {
      queryKey: 'clientInfo',
      queryFn: () => getclientInfo(cid),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  ]);

  return (
    <Box paddingTop={[8, 4]}>
      <Typography variant="h4" fontWeight="bold" paddingBottom={2} data-testid="client-title">
        {isFetching ? <Skeleton width={70} /> : client?.clientName || '404'}
      </Typography>
      <Breadcrumbs
        crumbs={[
          {
            name: String(client?.clientName || '404'),
          },
        ]}
        isLoading={isLoading || isFetching}
      />
      <CustomCard sx={{ mt: 2 }}>
        {typeof client === 'object' && Object.keys(client).length === 0 ? (
          <Box sx={{ py: 2 }}>
            <Alert severity="info" variant="outlined" sx={{ borderWidth: 0 }}>
              <AlertTitle>{t('client.no_client.title')}</AlertTitle>
              {t('client.no_client.description')}
            </Alert>
          </Box>
        ) : (
          <>
            <Box
              my={2}
              display="flex"
              flexDirection={['column', 'column', 'row']}
              gap={2}
              justifyContent="space-between"
            >
              <Box
                sx={{ p: 2 }}
                display="flex"
                flexDirection={['column', 'row']}
                alignItems={['flex-start', 'center']}
                gap={2}
              >
                {isInfoLoading ? (
                  <Skeleton
                    sx={{
                      opacity: 0.5,
                      width: '100%',
                      transform: 'scale(1)',
                    }}
                    width={240}
                    height={144}
                    animation="wave"
                  />
                ) : (
                  <Box
                    sx={{
                      bgcolor: theme => (theme.palette.mode === 'dark' ? theme.palette.grey[100] : 'transparent'),
                      maxWidth: { xs: '250px', sm: '280px' },
                    }}
                    borderRadius={2}
                    p={1}
                  >
                    <Image
                      src={`data:image/png;base64,${clientInfo?.logo}`}
                      style={{ margin: '0 auto', maxWidth: 'inherit' }}
                      alt="client logo"
                    />
                  </Box>
                )}
                {isInfoLoading ? (
                  <Skeleton
                    sx={{
                      opacity: 0.5,
                      transform: 'scale(1)',
                    }}
                    width={200}
                    height={144}
                    animation="wave"
                  />
                ) : clientInfo ? (
                  <address>
                    {clientInfo.address}
                    <br />
                    {clientInfo.city} {clientInfo.postCode ? `, ${clientInfo.postCode}` : ''}
                    <br /> <Link href={`tel:${clientInfo.phone}`}>{clientInfo.phone}</Link>
                    <br /> <Link href={`mailto:${clientInfo.email}`}>{clientInfo.email}</Link>
                    <br />
                    <b>{t('client.opening_hours')}:</b>
                    <br /> {clientInfo.openingHours}
                  </address>
                ) : null}
              </Box>
              <Box
                display="flex"
                flexDirection={['row', 'row', 'column']}
                alignItems="flex-end"
                justifyContent="space-between"
                sx={{ p: 2 }}
              >
                {client && String(client.clientId) === cid ? (
                  <AutogiroStatus client={client} size="large" />
                ) : (
                  <EnergySavingsLeafOutlinedIcon fontSize="large" color="disabled" sx={{ opacity: 0.1 }} />
                )}
                {client ? (
                  <Box>
                    <Box display="flex" justifyContent="flex-end" mb={1} gap={1} flexDirection="row">
                      <ClientContact clientName={client.clientName} clientId={client.clientId} />
                      <CustomerDetails clientName={client.clientName} clientId={client.clientId} />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" flexDirection="column">
                      <DistributionTypeSelection showCurrent defaultType={client.distributionType} />
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Divider
              flexItem
              sx={{
                my: 2,
                flexShrink: 0,
                borderColor: 'rgba(145, 158, 171, 0.24)',
                alignSelf: 'stretch',
                borderStyle: 'dashed',
                width: '100%',
                height: '1px',
              }}
            />
            <Box sx={{ overflow: ['scroll hidden', 'auto'] }}>
              <ClientInvoices client={client} />
            </Box>
          </>
        )}
      </CustomCard>
    </Box>
  );
}
