import { Access, AuthRequestParams } from 'interfaces/auth';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { STORAGE_KEY, TTL_KEY } from 'src/utils/enums';

interface Effect {
  type: string;
  params: any;
}

function createEffect(type: string, args = {}): Effect {
  return {
    params: args,
    type,
  };
}

interface ErrorResponse {
  response: {
    status: number;
    statusText?: string;
    data?: {
      message?: string;
    };
  };
}

type AuthStatus = 'initial' | 'unAuthenticated' | 'pending' | 'authenticated';

interface AuthenticationState {
  status: AuthStatus;
  auth: Access | null;
  error?: ErrorResponse;
  effects: Effect[];
}

interface Authenticate {
  type: 'authenticate';
  args: AuthRequestParams;
}

interface Resolve {
  type: 'resolve' | 'populate';
  args: Access;
}

interface Reject {
  type: 'reject';
  error: ErrorResponse;
}

interface BasicEvent {
  type: 'signOut' | 'invalid';
}

type AuthenticationEvent = Authenticate | BasicEvent | Resolve | Reject;

interface AuthContext {
  status: AuthStatus;
  auth: Access | null;
  authenticate: (args: AuthRequestParams) => void;
  signOut: () => void;
  populate: (args: Access) => void;
  validate: () => void;
  error?: ErrorResponse;
}

const defaultState: { status: AuthStatus; auth: Access | null } = {
  status: 'initial',
  auth: null,
};

const defaultAuthContext: AuthContext = {
  ...defaultState,
  authenticate: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  populate: () => undefined,
  validate: () => undefined,
};

const authenticationContext = createContext<AuthContext>(defaultAuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
  authenticate: (args: AuthRequestParams) => Promise<Access>;
  signOut: (args?: object) => Promise<object>;
}

function authenticationReducer(state: AuthenticationState, event: AuthenticationEvent): AuthenticationState {
  switch (state.status) {
    case 'initial':
    case 'unAuthenticated':
      if (event.type === 'invalid') {
        return {
          ...state,
          status: 'unAuthenticated',
          effects: [createEffect('signOut')],
        };
      }
      if (event.type === 'authenticate') {
        return {
          ...state,
          status: 'pending',
          effects: [createEffect('authenticate', event.args)],
        };
      }
      if (event.type === 'populate') {
        return {
          ...state,
          auth: event.args,
          status: 'authenticated',
          effects: [createEffect('storage', event.args)],
        };
      }
      return state;
    case 'pending':
      if (event.type === 'resolve') {
        return {
          ...state,
          auth: event.args,
          status: 'authenticated',
          effects: [createEffect('storage', event.args)],
        };
      }

      if (event.type === 'reject') {
        return {
          ...state,
          status: 'unAuthenticated',
          error: event.error,
        };
      }
      return state;

    case 'authenticated': {
      if (event.type === 'signOut') {
        return {
          ...state,
          status: 'unAuthenticated',
          effects: [createEffect('signOut')],
        };
      }
      return state;
    }
    default:
      return state;
  }
}

export function AuthenticationProvider(props: AuthProviderProps) {
  const { children, authenticate, signOut } = props;
  let signOutTimeout: any | undefined = undefined;

  const [state, dispatch] = useReducer(authenticationReducer, {
    ...defaultState,
    effects: [],
  });

  const expireTimer = () => {
    const expires = Number(localStorage.getItem(TTL_KEY));
    if (signOutTimeout !== undefined) {
      clearTimeout(signOutTimeout);
    }
    if (Date.now() >= expires) {
      dispatch({ type: 'signOut' });
    } else {
      const ttl = expires - Date.now();
      if (ttl > 0) {
        signOutTimeout = setTimeout(() => {
          dispatch({ type: 'signOut' });
        }, ttl);
      }
    }
  };

  useEffect(() => {
    for (const effect of state.effects) {
      switch (effect.type) {
        case 'authenticate': {
          authenticate(effect.params)
            .then(args => {
              dispatch({ type: 'resolve', args });
            })
            .catch(error => dispatch({ type: 'reject', error }));
          break;
        }

        case 'storage': {
          if (!localStorage.getItem(TTL_KEY)) {
            const accessResp = effect.params['authorizationResponse'] as Access['authorizationResponse'];
            const expSeconds = accessResp.expiresIn;
            const now = new Date();
            now.setSeconds(now.getSeconds() + expSeconds);
            const ttl = now.getTime();
            localStorage.setItem(TTL_KEY, String(ttl));
          }
          expireTimer();
          localStorage.setItem(STORAGE_KEY, JSON.stringify(effect.params));
          break;
        }

        case 'stopSignOutTimer': {
          if (signOutTimeout !== undefined) {
            clearInterval(signOutTimeout);
          }
          break;
        }

        case 'populate': {
          dispatch({ type: 'resolve', args: effect.params });
          break;
        }

        case 'signOut': {
          signOut();
          localStorage.removeItem(STORAGE_KEY);
          localStorage.removeItem(TTL_KEY);
          break;
        }
      }
    }
  }, [state.effects, authenticate, signOut]);

  const authenticateFn = useCallback((args: AuthRequestParams) => {
    dispatch({
      type: 'authenticate',
      args,
    });
  }, []);

  const signOutFn = useCallback(() => {
    dispatch({ type: 'signOut' });
  }, []);

  const populateFn = useCallback((args: Access) => {
    dispatch({ type: 'populate', args });
  }, []);

  const validateFn = () => {
    if (localStorage.getItem(STORAGE_KEY) !== null) {
      populateFn(JSON.parse(localStorage.getItem(STORAGE_KEY) || ''));
      expireTimer();
    } else {
      dispatch({ type: 'invalid' });
    }
  };

  const value = useMemo(
    () => ({
      authenticate: authenticateFn,
      signOut: signOutFn,
      validate: validateFn,
      populate: populateFn,
      ...state,
    }),
    [state, authenticateFn, signOutFn, populateFn, validateFn]
  );

  return <authenticationContext.Provider value={value}>{children}</authenticationContext.Provider>;
}

export function useAuthentication() {
  return useContext(authenticationContext);
}
