import { Link } from 'react-router-dom';

// material-ui
import { Divider, Grid, Typography, useTheme } from '@mui/material';

// project imports
import AuthWrapper from 'components/auth/AuthWrapper';
import AuthCardWrapper from 'components/auth/AuthCardWrapper';
import AuthBankID from 'components/auth/BankIDForm';
import Logo from 'src/layouts/Logo';
import bankIDImg from 'src/assets/images/BankID_logo.svg';
import bankIDImgWhite from 'src/assets/images/BankID_logo_white.svg';
import { useTranslation } from 'react-i18next';

// assets

// ================================|| AUTH - BANKID ||================================ //

const BankID = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
          <AuthCardWrapper>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item sx={{ mb: 1 }}>
                <Logo />
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                <img src={theme.palette.mode === 'light' ? bankIDImg : bankIDImgWhite} height={120} alt="bankID" />
              </Grid>
              <Grid item xs={12}>
                <AuthBankID />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid item container direction="column" alignItems="center" xs={12}>
                  <Typography
                    component={Link}
                    to="/"
                    color={theme.palette.primary.main}
                    variant="subtitle1"
                    sx={{ textDecoration: 'none' }}
                  >
                    {t('auth.back')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AuthCardWrapper>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default BankID;
