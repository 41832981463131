import { useState } from 'react';

import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { validateSSN } from 'src/utils/validations/ssn';
import { PasswordField } from './PasswordField';
import { registerCustomer } from 'src/api/auth';
import { useMutation } from 'react-query';
import { CircleLoader } from 'components/CircleLoader';
import { InfoOutlined } from '@mui/icons-material';
import { GTM } from 'src/utils/gtm';

// ===========================|| REGISTER ||=========================== //

type Inputs = {
  type: 'PRV' | 'FTG';
  email: string;
  customerNumber: string;
  password: string;
  ssn: string;
};

const RegisterForm = ({ ...rest }) => {
  const form = useForm<Inputs>({
    defaultValues: { type: 'PRV' },
  });
  const [checked, setChecked] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
  } = form;
  const [formState, setFormState] = useState<'initial' | 'submitting' | 'success'>('initial');

  const registerMutation = useMutation(registerCustomer, {
    onSuccess: data => {
      if (data.code === 10011) {
        setError(t('auth.register.error.already_exist'));
        setFormState('initial');
      } else {
        setFormState('success');
      }
    },
    onError: err => {
      const error = err as { response: { data: { code: number } } };
      if (error.response.data.code === 10011) {
        setError(t('auth.register.error.already_exist'));
        setFormState('initial');
      } else if (error.response.data.code === 10006) {
        setError(t('auth.register.error.not_registered'));
      }
      setFormState('initial');
    },
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    GTM.registerButton();
    setFormState('submitting');
    registerMutation.mutate({
      ...data,
      country: i18n.language,
      mailContext: {
        mailSubject: t('mail.register.mailSubject'),
        linkText: t('mail.register.registerText'),
        descriptionText: t('mail.register.descriptionText'),
        gdprText: t('mail.gdprText'),
        supportButton: t('mail.supportButton'),
        contact: t('mail.contact'),
      },
    });
  };

  return (
    <>
      {formState === 'initial' ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)} {...rest}>
          <Stack spacing={1}>
            <Controller
              render={props => (
                <ToggleButtonGroup
                  fullWidth
                  exclusive
                  color="primary"
                  {...props.field}
                  onChange={e => {
                    props.field.onChange((e.target as HTMLInputElement).value);
                  }}
                >
                  <ToggleButton value="PRV" key="private">
                    {t('auth.register.fields.type.private')}
                  </ToggleButton>
                  <ToggleButton value="FTG" key="company">
                    {t('auth.register.fields.type.business')}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              name="type"
              control={control}
            />
            <TextField
              fullWidth
              label={t(`auth.fields.${watch('type') === 'PRV' ? 'ssn' : 'orgnumber'}`)}
              error={Boolean(errors.ssn)}
              helperText={errors.ssn?.message}
              {...register('ssn', {
                required: { value: true, message: t('auth.validations.ssn.required') },
                validate: (value, values) =>
                  values.type === 'PRV' && i18n.language === 'sv' && validateSSN(value) !== true
                    ? (t('auth.validations.ssn.valid') as string)
                    : undefined,
              })}
              type="text"
            />
            <TextField
              fullWidth
              label={t('auth.fields.customerNumber')}
              error={Boolean(errors.customerNumber)}
              helperText={errors.customerNumber?.message}
              {...register('customerNumber', {
                required: {
                  value: true,
                  message: t('auth.validations.customerNumber.required'),
                },
              })}
              type="tel"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={<Typography fontSize={16}>{t('auth.register.tooltip.customerNumber')}</Typography>}
                      enterTouchDelay={50}
                      leaveTouchDelay={3000}
                      placement="top"
                    >
                      <InfoOutlined sx={{ cursor: 'help' }} />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label={t('auth.fields.email')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              {...register('email', {
                required: {
                  value: true,
                  message: t('auth.validations.email.required'),
                },
                validate: value =>
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    value
                  ) !== true
                    ? (t('auth.validations.email.valid') as string)
                    : undefined,
              })}
              type="email"
            />
            <PasswordField {...form} label={t('auth.fields.password')} fieldName="password" />
          </Stack>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={event => setChecked(event.target.checked)}
                    name="checked"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle1" color="primary">
                    {t('auth.register.terms.agree')} &nbsp;
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      component="a"
                      target="_blank"
                      href="https://www.nordfincapital.com/terms-and-conditions/gdpr"
                    >
                      {t('auth.register.terms.link')}
                    </Typography>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {error !== null ? <Alert severity="error">{error}</Alert> : null}

          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              disabled={!checked || isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('auth.register_button')}
            </Button>
          </Box>
        </form>
      ) : formState === 'submitting' ? (
        <CircleLoader text={t('auth.submitting')} />
      ) : formState === 'success' ? (
        <Alert severity="success">
          <AlertTitle>{t('auth.register.success.title')}</AlertTitle>
          {t('auth.register.success.description')}
        </Alert>
      ) : null}
    </>
  );
};

export default RegisterForm;
