import { useState } from 'react';

// material-ui
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { useAuthentication } from 'src/hooks/use-auth';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { GTM } from 'src/utils/gtm';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import bankIDImg from 'src/assets/images/BankID_logo.svg';
import bankIDImgWhite from 'src/assets/images/BankID_logo_white.svg';
import { useTranslation } from 'react-i18next';

type Inputs = {
  username: string;
  password: string;
};

function AuthForm() {
  const { authenticate, status, error } = useAuthentication();
  const { i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = data => {
    authenticate({ email: data.username, password: data.password });
    GTM.signInWithEmail();
  };
  const theme = useTheme();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const bankIDHandler = async () => {
    navigate('/bank-id');
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        {i18n.language !== 'fi' ? (
          <>
            <Grid item xs={12}>
              <Button
                disableElevation
                fullWidth
                onClick={bankIDHandler}
                size="large"
                variant="contained"
                color="primary"
              >
                {t('auth.sign_in_with_bankid')}{' '}
                <img src={theme.palette.mode === 'dark' ? bankIDImg : bankIDImgWhite} height={40} alt="bankID" />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider
                orientation="horizontal"
                sx={{
                  color: theme =>
                    theme.palette.mode === 'light' ? theme.palette.neutral['400'] : theme.palette.neutral['300'],
                  textTransform: 'uppercase',
                }}
              >
                {t('auth.or')}
              </Divider>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">{t('auth.sign_in_with_email')}</Typography>
          </Box>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <TextField
            fullWidth
            label={t('auth.fields.email')}
            disabled={isSubmitting || status === 'pending'}
            error={Boolean(errors.username)}
            helperText={errors.username?.message}
            {...register('username', { required: t('auth.validations.email.required') as string })}
            type="email"
          />
          <TextField
            fullWidth
            label={t('auth.fields.password')}
            disabled={isSubmitting || status === 'pending'}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            type={showPassword ? 'text' : 'password'}
            {...register('password', { required: t('auth.validations.password.required') as string })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
            onClick={() => GTM.forgotPasswordButton()}
            component={Link}
            to="/forgot-password"
            variant="subtitle1"
            color="primary"
            sx={{ textDecoration: 'none', cursor: 'pointer' }}
          >
            {t('auth.forgot_password_button')}
          </Typography>
        </Stack>
        {error?.response?.status === 400 ? (
          <Alert severity="error">{t('auth.errors.invalid_email_or_password')}</Alert>
        ) : null}
        <Box sx={{ mt: 2 }}>
          <Button
            disableElevation
            disabled={isSubmitting || status === 'pending'}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('auth.sign_in')}{' '}
            {isSubmitting || status === 'pending' ? (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            ) : null}
          </Button>
        </Box>
      </form>
    </>
  );
}

export default AuthForm;
