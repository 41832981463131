// ------------------------------- REACT IMPORT --------------------------------

import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

// ------------------------------- MATERIAL UI IMPORT --------------------------------
import {
  Badge,
  Box,
  Card,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ScheduleIcon from '@mui/icons-material/Schedule';

// ------------------------------- PROJECT IMPORT --------------------------------
import Transitions from 'components/Transitions';
import { getNotifications, setNotificationSeen } from 'src/api/notifications';
import { useTranslation } from 'react-i18next';
import { DateField } from 'components/DateField';
import { GTM } from 'src/utils/gtm';
import { CustomDialog } from 'components/CustomDialog';

// ------------------------------- TRUNCATE STRING FUNCTION --------------------------------
function truncateWords(sentence: string, amount: number, tail: string) {
  const words = sentence.split(' ');
  if (amount >= words.length) {
    return sentence;
  }
  const truncated = words.slice(0, amount);
  return `${truncated.join(' ')}${tail}`;
}

// ------------------------------- NOTIFICATION COMPONENT --------------------------------
export default function Notification() {
  // ------------------------------- VARIABLES DECLARETION --------------------------------
  const [open, setOpen] = useState(false);
  const [fetchCount, setFetchCount] = useState<number>(0);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState({ header: '', body: '' });

  // ------------------------------- USEEFFECT FUNCTION -------------------------------
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  // ------------------------------- FETCHING ALL NOTIFICATION NUMBER -------------------------------
  const list = useQuery({
    queryKey: ['getNotificationData', fetchCount],
    queryFn: () => getNotifications(),
    refetchIntervalInBackground: true,
    refetchInterval: 30000,
  });

  // ------------------------------- HANDLE CLOSE EVENT HANDLER -------------------------------
  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | TouchEvent) => {
    if ((anchorRef.current && anchorRef.current.contains(event.target as Node)) || dialogOpen) {
      return;
    }
    setOpen(false);
  };

  // ------------------------------- HANDLE TOGGLE EVENT HANDLER -------------------------------
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
    if (!prevOpen.current) {
      GTM.notificationButton();
    }
  };

  // ------------------------------- HANDLE SPECIFIC/SINGLE READ MORE MESSAGE EVENT HANDLER -------------------------------
  const handleReadMore = ({
    messageHeader,
    messageBody,
    id,
  }: {
    messageHeader: string;
    messageBody: string;
    id?: number;
  }) => {
    setDialogOpen(true);
    setMessage({ header: messageHeader, body: messageBody });
    if (id) {
      setNotificationSeen(id).then(() => {
        setFetchCount(prev => prev + 1);
      });
    }
  };

  // ------------------------------- HANDLE READ ALL MESSAGE EVENT HANDLER -------------------------------
  const handleAllRead = (ids: number[]) => {
    const promises = ids.map(id => setNotificationSeen(id));
    Promise.all(promises).then(() => setFetchCount(prev => prev + 1));
  };

  return (
    <>
      {/* ------------------------------- NOTIFICATION ICON SECTION ------------------------------- */}
      <IconButton
        size="large"
        aria-label={`show ${list.data?.length} new notifications`}
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={list.data?.filter(notification => !notification.isShown).length || 0} color="primary">
          <NotificationsNoneOutlinedIcon
            fontSize="medium"
            sx={{
              fill: theme =>
                theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
            }}
          />
        </Badge>
      </IconButton>
      {/* ------------------------------- NOTIFICATION POPPER SECTION START ------------------------------- */}
      <Popper placement="bottom-end" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Transitions in={open} type="fade" {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              {/* ------------------------------- NOTIFICATION CARD SECTION SART ------------------------------- */}
              <Card sx={{ minWidth: '360px' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                  <Box>
                    <Typography variant="body1" fontWeight={500}>
                      {t('notifications.title')}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme =>
                          theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[500],
                      }}
                      variant="body2"
                      fontWeight={300}
                    >
                      {t('notifications.unreadMessages', {
                        count: list.data?.filter(notification => !notification.isShown).length || 0,
                      })}
                    </Typography>
                  </Box>

                  {/* ------------------------------- MARK READ ALL TOOL TIP SECTION START ------------------------------- */}
                  {list.data?.filter(notification => !notification.isShown).length === 0 ? null : (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={<Typography fontSize={16}>{t('notifications.markAll')}</Typography>}
                      placement="bottom-end"
                    >
                      <IconButton
                        onClick={() =>
                          list.data
                            ? handleAllRead(
                                list.data?.filter(notification => !notification.isShown).map(n => n.notificationId)
                              )
                            : undefined
                        }
                        size="medium"
                        sx={{
                          '&:hover': {
                            transform: 'scale(1.15)',
                          },
                        }}
                      >
                        <DoneAllOutlinedIcon
                          fontSize="medium"
                          sx={{
                            fill: theme =>
                              theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {/* ------------------------------- MARK READ ALL TOOL TIP SECTION START ------------------------------- */}
                </Box>
                <Divider />
                <List sx={{ padding: 0, maxHeight: '500px', overflow: 'auto' }}>
                  {/*  ------------------------------- UNREAD HEADER SECTION STERT ------------------------------- */}
                  {list.data?.filter(notification => !notification.isShown).length === 0 ? null : (
                    <>
                      <Typography variant="body2" fontWeight={500} sx={{ py: 1, px: 2, textTransform: 'uppercase' }}>
                        {t('notifications.unread')}
                      </Typography>
                    </>
                  )}
                  {/*  ------------------------------- UNREAD HEADER SECTION END ------------------------------- */}

                  {/*  ------------------------------- RENDERING ALL UNREAD MESSAGES SECTION START ------------------------------- */}
                  {list.data
                    ?.filter(notification => !notification.isShown)
                    .map(notification => (
                      <ListItem
                        key={notification.notificationId}
                        divider
                        sx={{
                          padding: 'inherit',
                          opacity: notification.isShown ? 0.5 : 1,
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          borderColor: theme =>
                            theme.palette.mode === 'light'
                              ? theme.palette.neutral['100']
                              : theme.palette.neutral['900'],
                        }}
                      >
                        <ListItemButton
                          onClick={() =>
                            handleReadMore({
                              messageHeader: notification.messageHeader,
                              messageBody: notification.messageBody,
                              id: notification.notificationId,
                            })
                          }
                          sx={{
                            py: 1,
                            flexDirection: 'column',
                            alignItems: 'start',
                            width: '100%',
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: 18,
                              color: theme =>
                                theme.palette.mode === 'light'
                                  ? theme.palette.secondary.dark
                                  : theme.palette.secondary.light,
                            }}
                            secondaryTypographyProps={{
                              fontSize: 14,
                              color: theme =>
                                theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[400],
                            }}
                            primary={truncateWords(notification.messageHeader, 5, '...')}
                            secondary={truncateWords(notification.messageBody, 10, '...')}
                          />
                          <ListItemIcon sx={{ pb: '6px' }}>
                            <ScheduleIcon sx={{ fontSize: '18px' }} />
                            <Typography pl={1} fontSize="12px">
                              <DateField toDate={notification.messageDate} />
                            </Typography>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  {/*  ------------------------------- RENDERING ALL UNREAD MESSAGES SECTION END ------------------------------- */}

                  {/*  ------------------------------- READ HEADER SECTION START ------------------------------- */}
                  {list.data?.filter(notification => notification.isShown).length === 0 ? null : (
                    <>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        sx={{ padding: '8px 16px', textTransform: 'uppercase' }}
                      >
                        {t('notifications.read')}
                      </Typography>
                    </>
                  )}
                  {/*  ------------------------------- READ HEADER SECTION START ------------------------------- */}

                  {/*  ------------------------------- RENDERING ALL READ MESSAGES SECTION START ------------------------------- */}
                  {list.data
                    ?.filter(notification => notification.isShown)
                    .map(notification => (
                      <ListItem
                        key={notification.notificationId}
                        divider
                        sx={{
                          padding: 'inherit',
                          opacity: notification.isShown ? 0.5 : 1,
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <ListItemButton
                          onClick={() =>
                            handleReadMore({
                              messageHeader: notification.messageHeader,
                              messageBody: notification.messageBody,
                            })
                          }
                          sx={{
                            py: 1,
                            flexDirection: 'column',
                            alignItems: 'start',
                            width: '100%',
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: 18,
                              color: theme =>
                                theme.palette.mode === 'light'
                                  ? theme.palette.secondary.dark
                                  : theme.palette.secondary.light,
                            }}
                            secondaryTypographyProps={{
                              fontSize: 14,
                              color: theme =>
                                theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[400],
                            }}
                            primary={truncateWords(notification.messageHeader, 5, '...')}
                            secondary={truncateWords(notification.messageBody, 10, '...')}
                          />
                          <ListItemIcon sx={{ pb: '6px' }}>
                            <ScheduleIcon sx={{ fontSize: '18px' }} />
                            <Typography pl={1} fontSize="12px">
                              <DateField toDate={notification.messageDate} />
                            </Typography>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  {/*  ------------------------------- RENDERING ALL READ MESSAGES SECTION END ------------------------------- */}
                </List>
              </Card>
              {/* ------------------------------- NOTIFICATION CARD SECTION END ------------------------------- */}
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
      {/* ------------------------------- NOTIFICATION POPPER SECTION END ------------------------------- */}

      {/* ------------------------------- READ MORE DIALOG SECTION START ------------------------------- */}
      <CustomDialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        titleIcon={MarkEmailReadIcon}
        title={message.header}
        maxWidth="sm"
      >
        {message.body}
      </CustomDialog>
      {/* ------------------------------- READ MORE DIALOG SECTION END ------------------------------- */}
    </>
  );
}
