import { useState, useRef, useEffect } from 'react';

// material-ui
import { alpha, useMediaQuery, useTheme } from '@mui/material';
import {
  Card,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Typography,
} from '@mui/material';

// project imports
import Transitions from 'src/components/Transitions';
import { borderRadius } from 'src/theme/constants';
import { useAuthentication } from 'src/hooks/use-auth';

// assets
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { UserSettings } from './Settings';
import { useTranslation } from 'react-i18next';
import { sentry } from 'src/utils/sentry';
import { GTM } from 'src/utils/gtm';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { auth, signOut } = useAuthentication();
  const [open, setOpen] = useState(false);
  const settingsModal = useState(false);
  const { t } = useTranslation();
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any | null>(null);
  const handleLogout = async () => {
    signOut();
    GTM.signOutButton();
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (auth) {
      sentry.setUser(auth.authorizationResponse.customerName);
    }
  }, []);

  return (
    <>
      <Chip
        icon={
          <AccountCircleIcon
            sx={{
              fill: theme =>
                theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
            }}
          />
        }
        color="primary"
        variant="outlined"
        label={
          matches
            ? auth?.authorizationResponse.customerName
            : `${auth?.authorizationResponse.customerName.split(' ')[0][0]}${
                auth?.authorizationResponse.customerName.split(' ')[1][0]
              }`
        }
        sx={{
          color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.dark,
          fontWeight: 'bold',
          borderWidth: '2px',
          backgroundColor: alpha(theme.palette.primary.main, 0.2),
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8, 0, 0],
            },
          },
        ]}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {({ TransitionProps }: any) => (
          <Transitions in={open} type="fade" {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Card sx={{ p: 2 }}>
                <Typography variant="subtitle2" fontWeight="bold" pb={1}>
                  {auth?.authorizationResponse.customerName}
                </Typography>
                <Divider />
                <List
                  component="nav"
                  sx={{
                    width: '100%',
                    maxWidth: 350,
                    [theme.breakpoints.down('md')]: {
                      minWidth: '100%',
                    },
                    '& .MuiListItemButton-root': {
                      mt: 0.5,
                    },
                  }}
                >
                  <ListItemButton
                    sx={{ borderRadius: `${borderRadius}px !important` }}
                    onClick={() => {
                      settingsModal[1](true);
                      setOpen(false);
                      GTM.userSettingButton();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '36px' }}>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">{t('profile_menu.settings')}</Typography>} />
                  </ListItemButton>
                  <ListItemButton sx={{ borderRadius: `${borderRadius}px !important` }} onClick={handleLogout}>
                    <ListItemIcon sx={{ minWidth: '36px' }}>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="body2">{t('profile_menu.sign_out')}</Typography>} />
                  </ListItemButton>
                </List>
              </Card>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
      <UserSettings modalState={settingsModal} />
    </>
  );
};

export default ProfileSection;
