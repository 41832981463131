declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const eventTypes = {
  INIT: 'init',
  PAGE_VIEW: 'dynamicPageView',
  BANKID_SAME_DEVICE: 'bankIDSameDevice',
  BANKID_OTHER_DEVICE: 'bankIDOtherDevice',
  BANKID_SUCCESS: 'bankIDSuccess',
  BANKID_ERROR: 'bankIDError',
  DOWNLOAD_PDF: 'downloadPDF',
  ACTIVATE_AUTOGIRO: 'activateAutogiro',
  ACTIVATE_AUTOGIRO_Next: 'activateAutogiroNext',
  ACTIVATE_AUTOGIRO_BANKID_SAME_DEVICE: 'bankIDSameDeviceForAutogiro',
  ACTIVATE_AUTOGIRO_BANKID_OTHER_DEVICE: 'bankIDOtherDeviceForAutogiro',
  ACTIVATE_AUTOGIRO_BANKID_SUCCESS: 'bankIDSuccessForAutogiro',
  ACTIVATE_AUTOGIRO_BANKID_ERROR: 'bankIDErrorForAutogiro',
  SIGN_IN_WITH_EMAIL: 'signInWithEmail',
  SWISH_MODAL_OPEN: 'swishModalOpenEvent',
  SWISH_MODAL_PAY_BUTTON: 'swishModalPayButtonClickEvent',
  SWISH_PAY_SUCCESS: 'swishSubmitSuccess',
  SWISH_PAY_ERROR: 'swishSubmitError',
  NOTIFICATION_BUTTON: 'notificationButtonClick',
  SIGN_OUT_BUTTON: 'signOutButtonClick',
  CLIENT_PAGE_BUTTON: 'clientPageButtonClick',
  CONTACT_CLIENT_BUTTON: 'contactClientButtonClick',
  SEND_EMAIL_BUTTON: 'sendMailButtonClick',
  GO_TO_PAYMENT_PAGE_BUTTON: 'goToPaymentButtonClick',
  MY_INFO_BUTTON: 'myInfoButtonClick',
  FORGOT_PASSWORD_BUTTON: 'forgetPasswordButtonClick',
  FORGOT_PASSWORD_SEND_EMAIL_BUTTON: 'forgotPasswordSendButtonClick',
  EMAIL_SEND_SUCCESS: 'forgotPasswordeSendEmailSuccess',
  EMAIL_SEND_ERROR: 'forgotPasswordeSendEmailError',
  CREATE_ACCOUNT_BUTTON: 'createAccountButtonClick',
  CHANGE_PASSWORD_BUTTON: 'changePasswordButtonClick',
  CHANGE_PASSWORD_SAVE_BUTTON: 'changePasswordSaveButtonClick',
  USER_SETTING_BUTTON: 'userSettingButtonClick',
  REGISTER_BUTTON: 'registerButtonClick',
  SELECT_LANGUAGE_BUTTON: 'selectLanguageButtonClick',
};

const sendToGTM = (data: { event: string; [key: string]: string }) => {
  // Bail if not production build
  if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
    return;
  }
  if (window['dataLayer']) {
    window['dataLayer'].push(data);
  } else {
    console.warn(`dataLayer ${'dataLayer'} does not exist, has script be initialized`);
  }
};

export const GTM = {
  init: () => {
    const value = {
      environment: import.meta.env.VITE_ENVIRONMENT,
    };
    sendToGTM({ event: eventTypes.INIT, ...value });
  },
  pageView: (page: { path: string }) => {
    sendToGTM({
      event: eventTypes.PAGE_VIEW,
      pagePath: page.path,
    });
  },
  bankIDSameDevice: () => {
    sendToGTM({ event: eventTypes.BANKID_SAME_DEVICE });
  },
  bankIDOtherDevice: () => {
    sendToGTM({ event: eventTypes.BANKID_OTHER_DEVICE });
  },
  bankIDSubmit: ({ action, error }: { action: 'success' | 'error'; error?: string }) => {
    sendToGTM({
      event: action === 'success' ? eventTypes.BANKID_SUCCESS : eventTypes.BANKID_ERROR,
      ...(error ? { error } : {}),
    });
  },
  signInWithEmail: () => {
    sendToGTM({ event: eventTypes.SIGN_IN_WITH_EMAIL });
  },
  downloadPDF: (invoiceID: string, clientID: string) =>
    sendToGTM({ event: eventTypes.DOWNLOAD_PDF, invoiceID, clientID }),

  activateAutogiro: (clientID: string) => sendToGTM({ event: eventTypes.ACTIVATE_AUTOGIRO, clientID }),

  activateAutogiroNext: (clientID: string) => sendToGTM({ event: eventTypes.ACTIVATE_AUTOGIRO_Next, clientID }),
  bankIDSameDeviceForAutogiro: () => {
    sendToGTM({ event: eventTypes.ACTIVATE_AUTOGIRO_BANKID_SAME_DEVICE });
  },
  bankIDOtherDeviceForAutogiro: () => {
    sendToGTM({ event: eventTypes.ACTIVATE_AUTOGIRO_BANKID_OTHER_DEVICE });
  },
  bankIDSubmitForAutogiro: ({ action, error }: { action: 'success' | 'error'; error?: string }) => {
    sendToGTM({
      event:
        action === 'success' ? eventTypes.ACTIVATE_AUTOGIRO_BANKID_SUCCESS : eventTypes.ACTIVATE_AUTOGIRO_BANKID_ERROR,
      ...(error ? { error } : {}),
    });
  },
  swishModalOpen: (invoiceNumber: string, paymentReference: string) =>
    sendToGTM({ event: eventTypes.SWISH_MODAL_OPEN, invoiceNumber, paymentReference }),
  swishModalPayButton: (invoiceNumber: string, paymentReference: string) =>
    sendToGTM({ event: eventTypes.SWISH_MODAL_PAY_BUTTON, invoiceNumber, paymentReference }),
  swishSubmit: ({
    action,
    error,
    invoiceNumber,
    paymentReference,
  }: {
    action: 'success' | 'error';
    error?: string;
    invoiceNumber: string;
    paymentReference: string;
  }) => {
    sendToGTM({
      event: action === 'success' ? eventTypes.SWISH_PAY_SUCCESS : eventTypes.SWISH_PAY_ERROR,
      ...(error ? { error } : {}),
      invoiceNumber,
      paymentReference,
    });
  },

  notificationButton: () => sendToGTM({ event: eventTypes.NOTIFICATION_BUTTON }),
  signOutButton: () => sendToGTM({ event: eventTypes.SIGN_OUT_BUTTON }),
  clientPageButton: (clientId: string, clientName: string) =>
    sendToGTM({ event: eventTypes.CLIENT_PAGE_BUTTON, clientId, clientName }),
  contactClientButton: (clientId: string, clientName: string) =>
    sendToGTM({ event: eventTypes.CONTACT_CLIENT_BUTTON, clientId, clientName }),
  sendEmailButton: (clientId: string, clientName: string) =>
    sendToGTM({ event: eventTypes.SEND_EMAIL_BUTTON, clientId, clientName }),
  goToPaymentPageButton: (invoiceNumber: string) =>
    sendToGTM({ event: eventTypes.GO_TO_PAYMENT_PAGE_BUTTON, invoiceNumber }),
  myInfoButton: (clientId: string) => sendToGTM({ event: eventTypes.MY_INFO_BUTTON, clientId }),
  forgotPasswordButton: () => sendToGTM({ event: eventTypes.FORGOT_PASSWORD_BUTTON }),
  forgotPasswordSendEmailButton: () => sendToGTM({ event: eventTypes.FORGOT_PASSWORD_SEND_EMAIL_BUTTON }),
  forgotPasswordSendEmaiAction: ({ action }: { action: 'success' | 'error' }) => {
    sendToGTM({
      event: action === 'success' ? eventTypes.EMAIL_SEND_SUCCESS : eventTypes.EMAIL_SEND_ERROR,
    });
  },
  createAccountButton: () => sendToGTM({ event: eventTypes.CREATE_ACCOUNT_BUTTON }),
  changePasswordButton: () => sendToGTM({ event: eventTypes.CHANGE_PASSWORD_BUTTON }),
  changePasswordSaveButton: () => sendToGTM({ event: eventTypes.CHANGE_PASSWORD_SAVE_BUTTON }),
  userSettingButton: () => sendToGTM({ event: eventTypes.USER_SETTING_BUTTON }),
  registerButton: () => sendToGTM({ event: eventTypes.REGISTER_BUTTON }),
  selectLanguageButton: () => sendToGTM({ event: eventTypes.SELECT_LANGUAGE_BUTTON }),
};
