// project imports
import MainLayout from 'src/layouts/MainLayout';
import { AuthGuard } from 'src/utils/auth-guard';
import type { RouteObject } from 'react-router-dom';
import Dashboard from 'src/pages/Dashboard';
import Client from 'src/pages/Client';
import Invoice from 'src/pages/Invoice';
import AccessDenied from 'src/pages/AccessDenied';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: ':clientId',
          children: [
            { index: true, element: <Client /> },
            {
              path: ':id',
              children: [{ index: true, element: <Invoice /> }],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <AccessDenied />,
    },
  ],
};

export default MainRoutes;
