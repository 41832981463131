import { Client } from 'interfaces/client';
import { isNullOrUndefined } from './isNullOrUndefined';

const currency = {
  SE: {
    locale: 'sv-SE',
    type: 'SEK',
  },
  FI: {
    locale: 'fi-FI',
    type: 'EUR',
  },
};

export function setCurrency(value: string | number, countryCode: Client['countryCode']) {
  if (isNullOrUndefined(value) || !countryCode) return null;
  const data = typeof value === 'number' ? value : Number(value.replace(',', '.').replace(/[^0-9.-]+/g, ''));
  return new Intl.NumberFormat(currency[countryCode].locale, {
    style: 'currency',
    currency: currency[countryCode].type,
  }).format(data);
}

export function convertToNumber(value: string) {
  return Number(value.replace(',', '.').replace(/[^0-9.-]+/g, ''));
}
