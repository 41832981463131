import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { alpha, Box, styled, Typography } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';
import { useDarkMode } from 'src/hooks/use-theme-mode';
import { useTranslation } from 'react-i18next';

export default function ModeToggle() {
  const { isDarkMode, toggle } = useDarkMode();
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        component="input"
        type="checkbox"
        id="dark-mode-toggle"
        checked={isDarkMode}
        onChange={() => toggle()}
        sx={{ opacity: 0, position: 'absolute' }}
      />
      <Box
        component="label"
        htmlFor="dark-mode-toggle"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          verticalAlign: 'middle',
          flexDirection: 'row-reverse',
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <Switch isChecked={isDarkMode}>
          <Ball isChecked={isDarkMode}>
            {isDarkMode ? (
              <DarkModeOutlinedIcon sx={{ color: theme => theme.palette.green[200] }} fontSize="inherit" />
            ) : (
              <LightModeOutlinedIcon sx={{ color: theme => theme.palette.green[900] }} fontSize="inherit" />
            )}
          </Ball>
        </Switch>
        <Box>
          <Typography variant="h6" fontSize={16}>
            {t('profile_menu.color_switch.title')}
          </Typography>
          <Typography variant="body2">{t('profile_menu.color_switch.description')}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

interface CheckProps {
  isChecked: boolean;
}
const Ball = styled('div', {
  shouldForwardProp: isPropValid,
})<CheckProps>`
  background-color: ${({ isChecked, theme }) => (isChecked ? theme.palette.green[700] : theme.palette.green[200])};
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 2px;
  height: 16px;
  width: 16px;
  transform: translateX(0px);
  transition: transform 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.06);
  ${({ isChecked }) => (isChecked ? 'transform: translateX(17px);' : '')}
`;

const Switch = styled('div', {
  shouldForwardProp: isPropValid,
})<CheckProps>`
  background-color: ${({ isChecked, theme }) => (isChecked ? theme.palette.green[900] : theme.palette.green[300])};
  border: 2px solid transparent;
  border-color: ${({ isChecked, theme }) =>
    isChecked ? alpha(theme.palette.green[400], 0.2) : alpha(theme.palette.green[200], 0.4)};
  border-radius: 11px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 22px;
  width: 40px;
  transition: border-color 0.25s, background-color 0.25s;
  transform: scale(1.2);
`;
