import { CustomerInfo } from 'interfaces/customerInfo';
import { transport } from 'src/utils/transport';

const apiURL = import.meta.env.VITE_API_URL;

export const CustomerInfoEndpoints = {
  info: (clientId: string) => `${apiURL}/api/profile/customerProfileInfo/${clientId}`,
};

export const getCustomerInfo = (clientId: string): Promise<CustomerInfo[]> => {
  return transport.get(CustomerInfoEndpoints.info(clientId));
};
