import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { GTM } from 'src/utils/gtm';

export default function MinimalLayout() {
  const location = useLocation();

  useEffect(() => {
    GTM.pageView({ path: location.pathname });
  }, [location]);

  return <Outlet />;
}
