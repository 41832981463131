import { alpha, Box, Container, Divider } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import LogoSection from 'src/layouts/Logo';

const Footer = forwardRef((_, ref) => {
  const { t } = useTranslation();
  return (
    <Box
      component="footer"
      bgcolor={theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600])}
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      pb={5}
      ref={ref}
    >
      <Box
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900],
        }}
        width="100%"
      >
        <svg
          viewBox="0 0 1920 250"
          xmlns="http://www.w3.org/2000/svg"
          style={{ left: 0, right: 0, display: 'block', width: '100%', background: 'transparent' }}
        >
          <Box
            d="M1920 250H0V0s126.707 78.536 349.975 80.05c177.852 1.203 362.805-63.874 553.803-63.874 290.517 0 383.458 57.712 603.992 61.408 220.527 3.696 278.059-61.408 412.23-17.239"
            style={{ transition: 'fill 400ms ease-in-out 0s' }}
            component="path"
            sx={{ fill: theme => (theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800]) }}
          ></Box>
          <Box
            d="M1920 144s-467.917 116.857-1027.243-17.294C369.986 1.322 0 45.578 0 45.578V250h1920V144z"
            style={{ transition: 'fill 400ms ease-in-out 0s' }}
            component="path"
            sx={{ fill: theme => (theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]) }}
          ></Box>
          <Box
            d="M0 195.553s208.547-75.581 701.325-20.768c376.707 41.908 520.834-67.962 722.545-67.962 222.926 0 311.553 83.523 496.129 86.394V250H0v-54.447z"
            style={{ transition: 'fill 400ms ease-in-out 0s' }}
            component="path"
            sx={{ fill: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]) }}
          ></Box>
        </svg>
      </Box>
      <Container>
        <Divider
          sx={{
            '&:before, &:after': {
              borderColor: theme =>
                theme.palette.mode === 'light' ? theme.palette.grey[400] : alpha(theme.palette.grey[400], 0.3),
            },
            color: theme =>
              theme.palette.mode === 'light' ? theme.palette.neutral['400'] : theme.palette.green['300'],
            textTransform: 'uppercase',
          }}
          flexItem
        >
          {t('footer.collaboration')}
        </Divider>
        <Box mt={{ sm: 1, xs: 4 }} display="flex" justifyContent="center">
          <Box>
            <LogoSection small />
          </Box>
        </Box>
      </Container>
    </Box>
  );
});

Footer.displayName = 'Footer';

export default Footer;
