import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Hidden,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { contactClient } from 'src/api/client';
import { GTM } from 'src/utils/gtm';
import { CustomDialog } from 'components/CustomDialog';

type FormValues = {
  subject: string;
  message: string;
  fromMail: string;
};

interface Payload extends FormValues {
  clientId: string;
}

export function ClientContact({ clientName, clientId }: { clientName: string; clientId: number }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showSuccessMessage, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    reset();
  };
  const snackbarHandleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSuccess(false);
  };

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(data => {
    setError(false);
    setIsLoading(true);
    const payload: Payload = {
      ...data,
      clientId: String(clientId),
    };
    contactClient(payload)
      .then(() => {
        handleClose();
        setShowSuccess(true);
        GTM.sendEmailButton(String(clientId), String(clientName));
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  });

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={16}>
            {t('client.contact.button')} - {clientName}
          </Typography>
        }
        enterTouchDelay={50}
        leaveTouchDelay={3000}
        placement="top"
      >
        <Button
          variant="text"
          onClick={() => {
            setDialogOpen(true);
            GTM.contactClientButton(String(clientId), String(clientName));
          }}
          endIcon={<SendIcon />}
        >
          <Hidden only="xs">{t('client.contact.button')}</Hidden>
        </Button>
      </Tooltip>
      <CustomDialog
        onClose={handleClose}
        open={dialogOpen}
        titleIcon={MailOutlinedIcon}
        title={`${t('client.contact.title')} - ${clientName}`}
        maxWidth="sm"
      >
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <TextField
              error={Boolean(errors.fromMail)}
              helperText={errors.fromMail?.message}
              {...register('fromMail', {
                required: t('client.contact.validations.email.required') as string,
                validate: value =>
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    value
                  ) !== true
                    ? (t('auth.validations.email.valid') as string)
                    : undefined,
              })}
              fullWidth
              variant="outlined"
              label={t('client.contact.label.email')}
            />
            <TextField
              error={Boolean(errors.subject)}
              helperText={errors.subject?.message}
              {...register('subject', {
                required: { value: true, message: t('client.contact.validations.subject.required') },
                minLength: { value: 3, message: t('client.contact.validations.subject.min', { count: 3 }) },
              })}
              fullWidth
              variant="outlined"
              label={t('client.contact.label.subject')}
            />
            <TextField
              error={Boolean(errors.message)}
              helperText={errors.message?.message}
              {...register('message', {
                required: { value: true, message: t('client.contact.validations.message.required') },
                minLength: { value: 12, message: t('client.contact.validations.message.min', { count: 12 }) },
              })}
              fullWidth
              label={t('client.contact.label.message')}
              multiline
              rows={4}
            />
            {error ? <Alert severity="error">{t('client.contact.error')}</Alert> : null}
          </Stack>
          <Box display="flex" justifyContent="flex-end" mt={2} gap={1}>
            <Button type="button" onClick={handleClose}>
              {t('ui.close')}
            </Button>
            <Button type="submit" variant="contained" disabled={isLoading}>
              {t('ui.send')}{' '}
              {isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : null}
            </Button>
          </Box>
        </form>
      </CustomDialog>
      <Snackbar
        open={showSuccessMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={snackbarHandleClose}
        autoHideDuration={6000}
        sx={{ maxWidth: 300 }}
      >
        <Alert severity="success" onClose={snackbarHandleClose} sx={{ textAlign: 'left' }}>
          <strong>{t('client.contact.success')}</strong>
        </Alert>
      </Snackbar>
    </>
  );
}
