import { Access, Polling } from 'src/interfaces/auth';
import { Dispatch, useEffect, useReducer, useRef } from 'react';

interface Effect {
  type: string;
  status: 'idle' | 'started';
  markAsStarted(): void;
  [key: string]: any;
}

function createEffect(type: string, args = {}): Effect {
  return {
    type,
    status: 'idle',
    markAsStarted() {
      this.status = 'started';
    },
    ...args,
  };
}

export type BankIDStatus = 'idle' | 'pending_auth' | 'pending_poll' | 'awaiting_poll';

export interface BankIdState {
  /**
   * The current status
   */
  status: BankIDStatus;
  /**
   * The hint code
   */
  progress?: Polling['collectBankIdStatus'];
  /**
   * Number of attempts that have been completed.
   */
  pollAttempts: number;
  /**
   * The reference that was gathered from the auth call.
   */
  reference: string;
  /**
   * The current effects to be executed.
   */
  effects: Effect[];
}

type BankIdEvents =
  | { type: 'auth'; ssn?: string }
  | { type: 'resume_auth'; reference: string }
  | { type: 'resolve_auth'; reference: string }
  | { type: 'reject_auth'; error: any }
  | {
      type: 'resolve_poll';
      progress: 'pending' | 'success';
      auth?: Access | boolean;
      status?: Polling['collectBankIdStatus'];
    }
  | { type: 'reject_poll'; error: any }
  | { type: 'timeout_polling' }
  | { type: 'resume_polling' };

function reducer(state: BankIdState, event: BankIdEvents): BankIdState {
  const prevEffects = state.effects.filter(effect => effect.status === 'idle');

  switch (state.status) {
    case 'idle':
      if (event.type === 'auth') {
        return {
          ...state,
          effects: [...prevEffects, createEffect('auth', { ssn: event.ssn })],
          status: 'pending_auth',
        };
      }
      if (event.type === 'resume_auth') {
        return {
          ...state,
          reference: event.reference,
          effects: [...prevEffects, createEffect('poll', { reference: event.reference })],
          status: 'pending_poll',
        };
      }

      return state;
    case 'pending_auth':
      if (event.type === 'resolve_auth') {
        return {
          ...state,
          reference: event.reference,
          effects: [...prevEffects, createEffect('poll', { reference: event.reference })],
          status: 'pending_poll',
        };
      }

      if (event.type === 'reject_auth') {
        return {
          ...state,
          effects: [...prevEffects, createEffect('onAuthRejection', { error: event.error })],
          status: 'idle',
        };
      }

      return state;
    case 'pending_poll':
      if (event.type === 'resolve_poll') {
        if (event.auth) {
          return {
            ...state,
            pollAttempts: 0,
            reference: '',
            effects: [...prevEffects, createEffect('onResolved', { auth: event.auth })],
            status: 'idle',
          };
        }

        return {
          ...state,
          pollAttempts: state.pollAttempts + 1,
          progress: event.status,
          effects: [...prevEffects, createEffect('wait')],
          status: 'awaiting_poll',
        };
      }

      if (event.type === 'reject_poll') {
        return {
          ...state,
          effects: [...prevEffects, createEffect('onPollRejection', { error: event.error })],
          status: 'idle',
        };
      }

      return state;
    case 'awaiting_poll':
      if (event.type === 'resume_polling') {
        return {
          ...state,
          effects: [...prevEffects, createEffect('poll', { reference: state.reference })],
          status: 'pending_poll',
        };
      }

      if (event.type === 'timeout_polling') {
        return {
          ...state,
          effects: [...prevEffects, createEffect('onPollTimeout')],
          status: 'idle',
        };
      }

      return state;
    default:
      return state;
  }
}

export interface UseBankIdOptions {
  /**
   * Max number of polls allowed before rejecting.
   */
  pollLimit: number;
  /**
   * Number of milliseconds to wait before resuming polling.
   */
  pollWait: number;
  /**
   * The effects to be fired.
   */
  effects: {
    auth(ssn: string | undefined, dispatch: Dispatch<BankIdEvents>): void;
    poll(reference: string, dispatch: Dispatch<BankIdEvents>): void;
  };
  /**
   * Called if the auth call is rejected.
   */
  onAuthRejection?(error: any): void;
  /**
   * Called if the polling is rejected.
   */
  onPollRejection?(error: any): void;
  /**
   * Called if the polling timeout.
   */
  onPollTimeout?(): void;
  /**
   * Called if the chain resolves.
   */
  onResolved?(auth: Access): void;
}

export function useBankId(options: UseBankIdOptions): [BankIdState, Dispatch<BankIdEvents>] {
  const mounted = useRef(false);
  const [state, dispatch] = useReducer(reducer, {
    status: 'idle',
    pollAttempts: 0,
    reference: '',
    effects: [],
  });

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  function wrappedDispatch(event: BankIdEvents) {
    if (mounted.current) {
      dispatch(event);
    }
  }

  useEffect(() => {
    if (state.effects.length < 1) {
      return;
    }

    for (const effect of state.effects) {
      if (effect.status !== 'idle') {
        continue;
      }

      effect.markAsStarted();

      if (effect.type === 'auth') {
        options.effects.auth(effect.ssn, wrappedDispatch);
      } else if (effect.type === 'wait') {
        if (state.pollAttempts >= options.pollLimit) {
          wrappedDispatch({ type: 'timeout_polling' });
        } else {
          setTimeout(() => {
            wrappedDispatch({ type: 'resume_polling' });
          }, options.pollWait);
        }
      } else if (effect.type === 'poll') {
        options.effects.poll(effect.reference, wrappedDispatch);
      } else if (effect.type === 'onAuthRejection') {
        options.onAuthRejection?.(effect.error);
      } else if (effect.type === 'onPollRejection') {
        options.onPollRejection?.(effect.error);
      } else if (effect.type === 'onPollTimeout') {
        options.onPollTimeout?.();
      } else if (effect.type === 'onResolved') {
        options.onResolved?.(effect.auth);
      }
    }
  }, [
    state.effects,
    state.pollAttempts,
    options.effects.auth,
    options.effects.poll,
    options.onAuthRejection,
    options.onPollRejection,
    options.onPollTimeout,
    options.onResolved,
    options.pollLimit,
    options.pollWait,
  ]);

  return [state, wrappedDispatch];
}
