import { Box, Typography, Button, TextField, CircularProgress, Alert, AlertTitle } from '@mui/material';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import SwishLogo from 'src/assets/images/SwishLogo.svg';
import { useForm } from 'react-hook-form';
import { retrivePaymentStatus, swishEcommerceRequest } from 'src/api/invoices';
import { Invoice, SwishPaymentResponse, SwishResponse } from 'interfaces/invoice';
import { setCurrency } from 'src/utils/currency';
import { GTM } from 'src/utils/gtm';
import { CustomDialog } from 'components/CustomDialog';

interface SwishModalProps {
  invoice: Invoice;
  callback: () => void;
  children: ({ onClick }: { onClick: () => void }) => ReactNode;
}

type FormValues = {
  phoneNumber: string;
};

const SwishModal: React.FC<SwishModalProps> = ({ invoice, callback, children }: SwishModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {children({
        onClick: () => {
          setOpen(true);
          GTM.swishModalOpen(String(invoice.invoiceNumber), String(invoice.paymentReference));
        },
      })}
      {open ? <Modal isOpen={open} invoice={invoice} close={() => setOpen(false)} callback={callback} /> : null}
    </>
  );
};

function Modal({
  isOpen,
  close,
  invoice,
  callback,
}: {
  isOpen: boolean;
  close: () => void;
  invoice: Invoice;
  callback: () => void;
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const { t } = useTranslation();
  const [swishState, setSwishState] = useState<'initial' | 'pending' | 'success' | 'declined'>('initial');
  const instructionUUID = useRef<string | null>(null);
  const [error, setError] = useState(false);

  const {
    mutate,
    isLoading,
    isSuccess,
    reset: resetMutation,
  } = useMutation(swishEcommerceRequest, {
    onSuccess: (data: SwishResponse) => {
      if (data?.statusHeader === 'Created') {
        instructionUUID.current = data.instructionUUID;
        setError(false);
      } else if (data?.statusHeader === 'UnprocessableEntity') {
        instructionUUID.current = null;
        setError(true);
      }
    },
    onError: () => {
      setError(true);
    },
  });

  const { isLoading: isPaymentLoading } = useQuery(
    'retrivePaymentStatus',
    () =>
      instructionUUID.current && invoice.invoiceId
        ? retrivePaymentStatus(instructionUUID.current, invoice.invoiceId)
        : null,
    {
      onSuccess: (retrivePaymentResponse: SwishPaymentResponse) => {
        setError(false);
        setSwishState('pending');
        if (retrivePaymentResponse.status === 'DECLINED' || retrivePaymentResponse.status === 'ERROR') {
          instructionUUID.current = null;
          setSwishState('declined');
          GTM.swishSubmit({
            action: 'error',
            error: retrivePaymentResponse.status,
            invoiceNumber: invoice.invoiceNumber,
            paymentReference: invoice.paymentReference,
          });
        }

        if (retrivePaymentResponse.status === 'PAID' || retrivePaymentResponse.status === 'PENDING') {
          instructionUUID.current = null;
          setSwishState('success');
          GTM.swishSubmit({
            action: 'success',
            invoiceNumber: invoice.invoiceNumber,
            paymentReference: invoice.paymentReference,
          });
        }
      },
      enabled: isOpen && Boolean(instructionUUID.current) && isSuccess,
      refetchInterval: 2000,
    }
  );

  const handleClose = () => {
    if (swishState === 'success') {
      callback();
    }
    setSwishState('initial');
    resetMutation();
    reset();
    close();
  };

  const onSubmit = handleSubmit(data => {
    const payerAlias = data.phoneNumber.slice(1);
    const amount = invoice.remainingAmount;
    const callbackUrl = window.location.href;
    GTM.swishModalPayButton(String(invoice.invoiceNumber), String(invoice.paymentReference));
    mutate({
      payerAlias: payerAlias,
      amount: amount,
      callbackUrl: callbackUrl,
      paymentReference: invoice.paymentReference,
    });
    reset();
  });

  return (
    <CustomDialog
      onClose={handleClose}
      open={isOpen}
      titleIcon={PaymentOutlinedIcon}
      title={t('swish.payWithSwish')}
      maxWidth="sm"
    >
      <form onSubmit={onSubmit}>
        <Box p={3}>
          {isLoading || isPaymentLoading || swishState === 'pending' ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress size={120} />
              <Typography p={3} variant="h5">
                {t('swish.openSwishAccount')}
              </Typography>
            </Box>
          ) : swishState === 'initial' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography id="modal-modal-title" variant="h6" sx={{ fontSize: { xs: '16px', sm: '1.25rem' } }}>
                {t('invoice.remainingAmount')}: {setCurrency(invoice.remainingAmount, invoice.clientLand)}
              </Typography>

              {error && <Typography color="error">{t('swish.error')}</Typography>}

              <Box mt={2}>
                <Typography variant="body2" textAlign="center">
                  {t('swish.enterPhoneNumber')}
                </Typography>
                <Box mt={1}>
                  <Box mb={2}>
                    <TextField
                      {...register('phoneNumber', {
                        required: { value: true, message: t('swish.validations.required') },
                        validate: value =>
                          /^\+46 *7[\d]{8}$/.test(value) !== true
                            ? (t('swish.validations.valid') as string)
                            : undefined,
                      })}
                      fullWidth
                      id="phoneNumber"
                      variant="outlined"
                      label="+46xxxxxxxxx"
                      type="tel"
                      error={!!errors.phoneNumber}
                      helperText={errors && errors.phoneNumber && errors.phoneNumber.message}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                    />
                  </Box>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                      type="submit"
                      disableElevation
                      fullWidth
                      size="large"
                      variant="contained"
                      endIcon={
                        <Box
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            borderRadius: 4,
                            width: '28px',
                            height: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img src={SwishLogo} alt="Swish button" height="24px" />
                        </Box>
                      }
                    >
                      <Typography
                        component="span"
                        paddingLeft={1}
                        fontSize={{ xs: '.8rem', sm: '1rem' }}
                        fontWeight={{ xs: 'bold' }}
                      >
                        {t('swish.pay')}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : swishState === 'success' ? (
            <Alert severity="success">
              <AlertTitle>{t('swish.success.title')}</AlertTitle>
              {t('swish.success.description')}
            </Alert>
          ) : swishState === 'declined' ? (
            <Alert severity="error">
              <AlertTitle>{t('swish.swishFailed')}</AlertTitle>
            </Alert>
          ) : null}
        </Box>
      </form>
    </CustomDialog>
  );
}

export default SwishModal;
